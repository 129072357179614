import React from "react";
import './Embed.css'

const Embed = ({src}) => {
  return (
    <main>
      <div className="embed-container">
        <table
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          style={{ borderCollapse: "collapse", maxWidth: "100%" }}
        >
          <tbody>
            <tr>
              <td align="center">&nbsp;</td>
            </tr>
            <tr>
              <td>
                <iframe
                  allowFullScreen={true}
                  src={src}
                  style={{ border: "none", width: "100%", height: "600px" }}
                  title="Embed"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default Embed;
