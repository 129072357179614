import React, { useLayoutEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Section,
  Card,
  Loading,
  StoreFrontHeader,
  Border,
  Copy
} from "@lucio-erasmus/sterns-components";
import { NewsletterContainer } from "../../containers";
import { getPage } from "../../helpers/wp";
// import { Embed } from "../../components";
import { HomeImageContext } from "../../components/Store/HomeImageContext";
import Seo from "../../components/Seo/Seo";

const HomeContainer = props => {
  const [p, setPage] = useState({ loading: true, page: null });
  const { setImage } = useContext(HomeImageContext);

  useLayoutEffect(() => {
    setPage({
      loading: true,
      page: null
    });
    getPage("/home").then(data => {
      setPage({
        loading: false,
        page: data
      });
        if (
          data.heroImageOptions.heroImage &&
          data.heroImageOptions.heroImage.url
        ) {
          setImage(data.heroImageOptions.heroImage.url);
        }
        
    });
  }, [props.match.params]);

  if (p.loading) return <Loading />;
  const { metaDesc, fullHead } = p.page.seo;

  return (
    <>
      <Seo key="seo" metaDesc={metaDesc} fullHead={fullHead} />
      {p.page && p.page.pageOptions && p.page.pageOptions.isFullWidth ? (
        <div dangerouslySetInnerHTML={{ __html: p.page.content }} />
      ) : (
        <>
          {
            <StoreFrontHeader
              headline={
                p.page && !p.page.headlineOptions.hideText
                  ? p.page && p.page.headlineOptions.headline
                    ? p.page.headlineOptions.headline
                    : p.page.title
                  : null
              }
              image={
                p.page &&
                p.page.heroImageOptions.heroImage &&
                p.page.heroImageOptions.heroImage.url
              }
              description={
                p.page && !p.page.headlineOptions.hideText
                  ? p.page && p.page.headlineOptions.subheading
                  : null
              }
            />
          }
          <div className="container entry">
            <Copy html={p.page && p.page.content} />
            <Border color="white" />
          </div>
        </>
      )}
      {p.page && p.page.parentDatabaseId === null ? (
        <Section rowClass="row justify-content-center" mb="none">
          {p.page.pageOptions.childPageMenu &&
            p.page.pageOptions.childPageMenu.map(child => {
              const ItemCard = () => {
                return (
                  <Card
                    portrait
                    vertical
                    title={child.title || child.headlineOptions.headline}
                    description={child.pageOptions.excerpt}
                    image={
                      child.featuredImage && child.featuredImage.node.sourceUrl
                        ? {
                            url: child.featuredImage.node.sourceUrl,
                            hotspot: ["50%", "50%"]
                          }
                        : null
                    }
                  />
                );
              };

              if (child.parentDatabaseId !== null) {
                return (
                  <div className="col-6 col-md-4" key={child.id}>
                    {child.pageOptions && child.pageOptions.linksToBash ? (
                      <a
                        href={child.pageOptions.bashcomDestination}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={child.title || child.headlineOptions.headline}
                      >
                        <ItemCard />
                      </a>
                    ) : (
                      <Link to={child.uri}>
                        <ItemCard />
                      </Link>
                    )}
                  </div>
                );
              } else if (
                child.parentDatabaseId === null &&
                child.title !== "Home"
              ) {
                return (
                  <div className="col-6 col-md-4" key={child.id}>
                    {child.pageOptions && child.pageOptions.linksToBash ? (
                      <a
                        href={child.pageOptions.bashcomDestination}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={child.headlineOptions.headline || child.title}
                      >
                        <ItemCard />
                      </a>
                    ) : (
                      <Link to={child.uri}>
                        <ItemCard />
                      </Link>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}

          {p.page.pageOptions.personalisedChildPageMenu &&
            p.page.pageOptions.personalisedChildPageMenu.map(child => {
              return (
                <div className="col-6 col-md-4" key={child.id}>
                  <Link to={child.uri}>
                    <Card
                      portrait
                      vertical
                      title={child.name || child.title}
                      description={child.description}
                      image={
                        child.image && child.image.sourceUrl
                          ? {
                              url: child.image.sourceUrl,
                              hotspot: ["50%", "50%"]
                            }
                          : null
                      }
                    />
                  </Link>
                </div>
              );
            })}
        </Section>
      ) : null}
      {/* {p.page && p.page.pageOptions && p.page.pageOptions.publitas ? (
        <Embed src={p.page.pageOptions.publitasUrl} />
      ) : null} */}
      <NewsletterContainer />
    </>
  );
};

export default HomeContainer;
