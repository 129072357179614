export const getCanvasBlob = canvas => {
  return new Promise(function(resolve, reject) {
    canvas.toBlob(function(blob) {
      resolve(blob);
    });
  });
};

export const convertBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const dataURItoBlob = dataURI => {
  return new Promise(function(resolve, reject) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return resolve(new Blob([ia], { type: mimeString }));
  });
};

export const getObjectPos = canvas => {
  if (canvas.objects && canvas.objects.length) {
    const [, userImg] = canvas.objects;
    if (userImg) {
      return [
        userImg.left,
        userImg.top,
        userImg.angle,
        userImg.scaleX,
        userImg.scaleY,
        userImg.width,
        userImg.height
      ];
    }

    return null;
  }

  return null;
};

export const formatPrice = price => {
  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
};

export const calculateCharLimit = (
  selectedComponents,
  additionalLimits = [],
  limit
) => {
  if (
    selectedComponents.length &&
    additionalLimits &&
    additionalLimits.length
  ) {
    const hasShapes = additionalLimits.some(l => l.shapes && l.shapes.length);
    const size = selectedComponents.find(c => c.sku === "eng-size");
    if (hasShapes) {
      const shape = selectedComponents.find(c => c.sku === "eng-shape");
      if (size && shape) {
        const charLimitObj = additionalLimits.find(
          l => size.id === l.size && l.shapes.includes(shape.id)
        );
        return charLimitObj.limit;
      }
    }

    const limitResult = additionalLimits.find(l => l.size === size.id);
    return limitResult.limit;
  }

  return limit;
};

/* WIP: version 3 of the character limit calculator.  */

// export const calculateCharLimitV3 = (
//   selectedComponents,
//   additionalLimits = {},
//   limit
// ) => {
//   if (additionalLimits) {
//     const { required, params } = additionalLimits;

//     if (selectedComponents.length) {
//       const hasRequiredComponent = required.find(r => {
//         let hasRequired = false;
//         selectedComponents.forEach(c => {
//           if (r === c.sku) {
//             hasRequired = true;
//           }
//         });
//         return hasRequired;
//       });
//     }
//   }

//   return limit;
// };

export const getItemKey = (items = [], id, key) => {
  const option = items.find(item => item.id === id);
  if (option) {
    return option[key];
  }
  return id;
};

export const getProductConfig = (configuration, components) => {
  return configuration.reduce((result, item) => {
    if (item.specification) {
      Object.keys(item.specification).forEach(spec => {
        const componentInfo = components.find(f => f.sku === spec) || {};
        if (componentInfo.type === "engrave") {
          result.push({
            ...componentInfo,
            id: item.specification[spec],
            value: item.specification[spec]
          });
        } else if (componentInfo.type) {
          result.push({
            ...componentInfo,
            name: getItemKey(
              componentInfo.items,
              item.specification[spec],
              "name"
            ),
            id: item.specification[spec]
          });
        }
      });
    } else {
      result.push(item);
    }

    return result;
  }, []);
};

export const getConfigurationOptions = components => {
  return components.reduce((result, comp) => {
    if (["selection", "additional"].includes(comp.type)) {
      comp.options.forEach(opt => {
        result.push(opt);
      });
    } else {
      result.push(comp);
    }

    return result;
  }, []);
};

export const formatLocationData = (
  placeId,
  formattedAddressObject,
  formattedAddressString,
  formattedAddressStringWithoutCountry,
  geoLocation,
  staticMapURL
) => {
  return {
    placeId,
    formattedAddressObject,
    formattedAddressString,
    formattedAddressStringWithoutCountry,
    geoLocation,
    staticMapURL
  };
};

export const iterateAdreessAndRetrieve = enteredLocation => {
  // Get each component of the address from the enteredLocation details
  // and fill the corresponding field on the form.
  let addressObject = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    sublocality_level_1: "short_name",
    sublocality: "short_name",
    country: "short_name",
    postal_code: "short_name"
  };

  let returnObject = {};

  for (let i = 0; i < enteredLocation.address_components.length; i++) {
    enteredLocation.address_components[i].types.forEach(addressType => {
      if (addressObject[addressType]) {
        let val =
          enteredLocation.address_components[i][addressObject[addressType]];
        returnObject[addressType] = val;
      }
    });
  }
  return returnObject;
};

export const formatAddressObject = addressObject => {
  const formattedAddressObject = {};
  const street = `${
    addressObject["street_number"] ? addressObject["street_number"] : ""
  } ${addressObject["route"] ? addressObject["route"] : ""}`;

  formattedAddressObject["shipping.streetNumber"] =
    addressObject["street_number"];
  formattedAddressObject["shipping.addressLine1"] =
    street.trim() !== "" ? street.trim() : null;
  formattedAddressObject["shipping.suburb"] = addressObject[
    "sublocality_level_1"
  ]
    ? addressObject["sublocality_level_1"]
    : addressObject["sublocality"];
  formattedAddressObject["shipping.city"] = addressObject["locality"];
  formattedAddressObject["shipping.province"] =
    addressObject["administrative_area_level_1"];
  formattedAddressObject["shipping.country"] = addressObject["country"];
  formattedAddressObject["shipping.postalCode"] = addressObject["postal_code"];
  return formattedAddressObject;
};
