import React from "react";
import PropTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import { omit } from "ramda";
import {
  getCanvasBlob,
  dataURItoBlob,
  convertBlobToBase64,
  getObjectPos
} from "../../helpers/utils";
import ShopContainer from "../../containers/Content/ShopContainer";
import { screenshot } from "../../containers/Ring/v3d/config";

export const ProductContext = React.createContext();

class CartContext extends React.Component {
  state = {
    loading: false,
    error: null,
    usrImg: null,
    imagePosition: [],
    product: [],
    cart: [],
    shippingDetails: {},
    errors: {}
  };

  getChildContext() {
    return {
      loading: this.state.loading,
      error: this.state.error,
      shippingDetails: this.state.shippingDetails,
      setShippingDetails: payload => this.setShippingDetails(payload),
      setShippingMethod: this.onSetShippingMethod,
      setPaymentMethod: this.onSetPaymentMethod,
      getShippingMethods: this.getShippingMethods,
      setConfig: c => this.setConfig(c),
      resetProduct: () => this.resetProduct(),
      loadProduct: entry => this.onLoadProduct(entry),
      onProductAdd: item => this.onProductAdd(item),
      onProductDelete: item => this.onProductDelete(item),
      getProduct: () => this.state.product,
      getUserImage: () => [this.state.usrImg].concat(this.state.imagePosition),
      getErrors: sku => this.state.errors,
      addToCart: (product, cartId) => this.onAddToCart(product, cartId),
      duplicateProduct: entry => this.duplicateProduct(entry),
      removeFromCart: entry => this.removeFromCart(entry),
      getTotal: p => this.onGetTotal(p),
      cart: () => this.getCart()
    };
  }

  getShippingMethods = () => {
    if (this.props.eligibleShippingMethods) {
      return this.props.eligibleShippingMethods;
    }
    return [];
  };

  setShippingDetails = payload => {
    this.setState(state => ({
      shippingDetails: {
        ...state.shippingDetails,
        ...payload
      }
    }));
  };

  componentDidMount() {}

  getCart = () => {
    if (this.props.cart) {
      return this.props.cart;
    }
    return null;
  };

  onLoadProduct = id => {
    if (this.props.cart) {
      const entry = this.props.cart.entries.find(entry => entry.id === id);
      if (entry) {
        this.setConfig(entry);
      }
    }
  };

  resetProduct = () => {
    this.setState({
      loading: false,
      error: null,
      usrImg: null,
      imagePosition: [],
      product: [],
      errors: {}
    });
  };

  getConfigItemErrors = item => {
    const { product } = this.state;

    switch (item.type) {
      case "additional": {
        const errors = {};

        let key = "sku";
        if (item.parentSku) {
          key = "parentSku";
        }
        const hasProduct = product.some(p => {
          return p.sku === item[key];
        });

        if (hasProduct) {
          item.options.forEach(c => {
            const cE = this.getConfigItemErrors(c);
            if (cE) {
              errors[c.sku] = [cE];
            }
          });
        }

        return Object.keys(errors).length ? errors : null;
      }
      case "selection": {
        const errors = {};
        item.options.forEach(c => {
          const cE = this.getConfigItemErrors(c);
          if (cE) {
            errors[c.sku] = [cE];
          }
        });
        return Object.keys(errors).length ? errors : null;
      }

      case "styles": {
        const errors = {};
        if (item.required) {
          const e = product.find(p => p.sku === item.sku);
          if (!e) {
            errors[item.sku] = `This option is required`;
          }
          item.styles.forEach(c => {
            const cE = this.getConfigItemErrors(c);

            if (cE) {
              errors[c.sku] = cE;
            }
          });
        }
        return Object.keys(errors).length ? errors : null;
      }

      case "singleText": {
        if (item.required) {
          const e = product.find(p => {
            if (item.parentSku) {
              return p.parent === item.parentSku && p.specification[item.sku];
            }
            return p.sku === item.sku;
          });

          if (!e) {
            return `This option is required`;
          }
        }
        return null;
      }

      case "engrave": {
        if (item.required) {
          const e = product.find(p => p.sku === item.sku);
          if (!e || !e.id) {
            return `This option is required`;
          }
        }
        return null;
      }

      case "single": {
        if (item.required) {
          const e = product.find(p => {
            if (item.parentSku) {
              return p.parent === item.parentSku && p.specification[item.sku];
            }
            return p.sku === item.sku;
          });

          if (!e) {
            return `This option is required`;
          }
        }
        return null;
      }

      case "upload": {
        if (item.required) {
          const e = product.find(p => p.sku === item.sku);
          if (!e) {
            return `This option is required`;
          }
        }
        return null;
      }
      default:
        return null;
    }
  };

  setConfig = async product => {
    if (
      product.productType === "personalisation" &&
      product.images &&
      product.images.length
    ) {
      const [a, b] = product.images;

      const [user, upload] = await Promise.all([
        fetch(`${process.env.REACT_APP_PRODUCT}${a._id}`).then(res =>
          res.blob()
        ),
        fetch(`${process.env.REACT_APP_PRODUCT}${b._id}`).then(res =>
          res.blob()
        )
      ]);

      return this.setState({
        product: await Promise.all(
          product.configuration.map(async c => {
            if (c.type === "upload") {
              return {
                ...c,
                image: await convertBlobToBase64(user),
                original: upload
              };
            }

            return c;
          })
        ),
        usrImg: await convertBlobToBase64(user),
        original: upload,
        imagePosition: product.imagePosition
      });
    }

    return this.setState({
      product: product.configuration
    });
  };

  getProductRequirements = p => {
    const {
      configuration: { components }
    } = p;

    let errors = {};

    components.forEach(c => {
      const cE = this.getConfigItemErrors(c);
      if (cE) {
        if (c.type === "styles") {
          errors = cE;
        } else {
          errors[c.sku] = cE;
        }
      }
    });

    return errors;
  };

  onSetShippingMethod = async id => {
    this.setState({
      loading: true,
      error: null,
      errors: {}
    });

    await this.props
      .setShippingMethod({
        variables: {
          id
        }
      })
      .then(() => {
        return this.props.refetch();
      })
      .then(() => {
        this.setState({
          loading: false,
          error: null,
          errors: {}
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: "Error setting shipping method",
          errors: {}
        });
      });
  };

  onSetPaymentMethod = async id => {
    this.setState({
      loading: true,
      error: null,
      errors: {}
    });

    await this.props
      .setPaymentMethod({
        variables: {
          id
        }
      })
      .then(() => {
        return this.props.refetch();
      })
      .then(() => {
        this.setState({
          loading: false,
          error: null,
          errors: {}
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: "Error setting payment method",
          errors: {}
        });
      });
  };

  onAddToCart = async (product, id = null, quantity = 1) => {
    this.setState({
      loading: true,
      error: null,
      errors: {}
    });

    const errors = this.getProductRequirements(product);

    if (Object.keys(errors).length) {
      this.setState({
        loading: false,
        errors
      });
      return Promise.reject();
    }

    if (this.state.product.some(p => p.type === "upload")) {
      let canvasJSON;
      if (window.the_canvas) {
        canvasJSON = window.the_canvas.toJSON();
        window.the_canvas.discardActiveObject();
        window.the_canvas.renderAll();
      }

      return Promise.resolve(this.state.product)
        .then(config => {
          const upload = config.find(p => p.type === "upload");
          const canvas = document.getElementById("main-canvas");

          return Promise.all([
            dataURItoBlob(upload.image),
            upload.original,
            getCanvasBlob(canvas),
            config,
            getObjectPos(canvasJSON)
          ]);
        })
        .then(([upload, original, canvas, config, imgPos]) => {
          upload.name = "upload.png";
          canvas.name = "canvass.png";

          return this.props.addToCart({
            variables: {
              input: {
                productId: product.id,
                configuration: [
                  ...config.map(p =>
                    omit(
                      [
                        "__typename",
                        "configId",
                        "styles",
                        "image",
                        "original",
                        "exclusions",
                        "specs",
                        "parentSku"
                      ],
                      p
                    )
                  )
                ],
                quantity,
                id,
                imagePosition: imgPos,
                files: [upload, canvas, original]
              }
            }
          });
        })
        .then(() => {
          this.setState({
            error: null,
            loading: false
          });

          return this.props.refetch();
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err
          });
        });
    }

    const files = [];
    if (product.productType === "dyor") {
      const capture = await dataURItoBlob(await screenshot());
      if (capture) {
        capture.name = "canvass.png";
        files.push(capture);
      }
    }

    return this.props
      .addToCart({
        variables: {
          input: {
            productId: product.id,
            configuration: [
              ...this.state.product.map(p =>
                omit(
                  [
                    "__typename",
                    "configId",
                    "styles",
                    "exclusions",
                    "specs",
                    "parentSku"
                  ],
                  p
                )
              )
            ],
            quantity,
            id,
            files
          }
        }
      })
      .then(() => {
        this.setState({
          error: null,
          loading: false
        });

        return this.props.refetch();
      })
      .catch(err => {
        this.setState({
          error: err,
          loading: false
        });
      });
  };

  duplicateProduct = entry => {
    this.props
      .duplicateProduct({
        variables: {
          input: {
            id: entry
          }
        }
      })
      .then(() => {
        this.props.refetch();
      })
      .catch(err => {
        console.log(err);
      });
  };

  removeFromCart = entry => {
    this.props
      .removeFromCart({
        variables: {
          input: {
            id: entry
          }
        }
      })
      .then(() => {
        this.props.refetch();
      })
      .catch(err => {
        console.log(err);
      });
  };

  onGetTotal = p => {};

  onGetProduct = () => {
    return this.state.product;
  };

  checkForIncomptabilities = array => {
    const currentConfig = [...array];
    // remove incompatibility from configuration
    const a = array.filter(item => {
      if (item.exclusions && item.exclusions.length) {
        return item.exclusions.some(exclude => {
          const currentItem = currentConfig.find(i => i.sku === exclude.key);
          return !(currentItem && exclude.values.includes(currentItem.id));
        });
      }

      return true;
    });

    console.log(a);

    return a;
  };

  onProductAdd = item => {
    this.setState(state => ({
      product: this.checkForIncomptabilities(
        this.updateProduct(state.product, item)
      ),
      errors: {}
    }));
  };

  onProductDelete = item => {
    this.setState(state => {
      if (item.parent) {
        return {
          product: state.product
            .map(p => {
              if (p.sku === item.parent) {
                const tempSpec = {
                  ...p.specification
                };

                delete tempSpec[item.sku];

                return {
                  ...p,
                  specification: tempSpec
                };
              }

              return p;
            })
            .filter(i => {
              if (item.connected && item.connected.length) {
                return !(item.connected.includes(i.sku) || i.sku === item.sku);
              }

              return Object.keys(i.specification).length;
            })
        };
      }

      return {
        product: state.product.filter(i => {
          if (item.connected && item.connected.length) {
            return !(item.connected.includes(i.sku) || i.sku === item.sku);
          }
          return i.sku !== item.sku;
        })
      };
    });
  };

  updateProduct = (array, item) => {
    let key = "sku";

    if (item.parent) {
      key = "parent";
    }

    const index = array.map(i => i[key]).indexOf(item[key]);

    if (index > -1) {
      return array.map(i => {
        if (i[key] === item[key]) {
          if (item.parent) {
            return {
              ...i,
              specification: {
                ...i.specification,
                ...(item.specs || { [item.sku]: item.id })
              }
            };
          }

          return {
            ...item
          };
        }
        return i;
      });
    } else {
      if (item.parent) {
        return [
          ...array,
          {
            sku: item.parent,
            specification: {
              ...(item.specs || { [item.sku]: item.id })
            },
            parent: item.parent || null
          }
        ];
      }
      return [...array, item];
    }
  };

  render() {
    if (this.props.loading) return null;

    return (
      <ProductContext.Provider
        value={{
          loading: this.state.loading,
          error: this.state.error,
          shippingDetails: this.state.shippingDetails,
          setShippingDetails: payload => this.setShippingDetails(payload),
          getShippingMethods: this.getShippingMethods,
          setShippingMethod: this.onSetShippingMethod,
          setPaymentMethod: this.onSetPaymentMethod,
          setConfig: c => this.setConfig(c),
          resetProduct: () => this.resetProduct(),
          loadProduct: entry => this.onLoadProduct(entry),
          onProductAdd: item => this.onProductAdd(item),
          onProductDelete: item => this.onProductDelete(item),
          getProduct: () => this.state.product,
          getUserImage: () =>
            [this.state.usrImg].concat(this.state.imagePosition),
          getErrors: sku => this.state.errors,
          addToCart: (product, cartId) => this.onAddToCart(product, cartId),
          duplicateProduct: entry => this.duplicateProduct(entry),
          removeFromCart: entry => this.removeFromCart(entry),
          getTotal: p => this.onGetTotal(p),
          cart: () => this.getCart()
        }}
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}

CartContext.childContextTypes = {
  setShippingDetails: PropTypes.func,
  shippingDetails: PropTypes.any,
  getShippingMethods: PropTypes.func,
  setShippingMethod: PropTypes.func,
  setPaymentMethod: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  setConfig: PropTypes.func.isRequired,
  resetProduct: PropTypes.func.isRequired,
  loadProduct: PropTypes.func.isRequired,
  onProductAdd: PropTypes.func.isRequired,
  onProductDelete: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  duplicateProduct: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  getUserImage: PropTypes.func.isRequired,
  getErrors: PropTypes.func.isRequired,
  getTotal: PropTypes.func.isRequired,
  cart: PropTypes.func.isRequired
};

const CART_QUERY = gql`
  ${ShopContainer.fragments.components}
  query cart {
    eligibleShippingMethods {
      id
      price
      description
    }
    cart {
      shipping_method
      payment_method
      shipping_price
      entries {
        id
        images {
          _id
          type
        }
        imagePosition
        productId
        product {
          name
          slug
          regular_price
          onSale: on_sale
          salesPrice: salesPriceText
          featuredImage {
            hasImage
            url
            hotspot
          }
          configuration {
            id
            components {
              ...NameParts
              options {
                ...NameParts
                styles {
                  ...NameParts
                }
                items {
                  name
                  group
                  image
                  description
                  assetImage
                  id
                  sku
                  parentSku
                  price
                  priceCategory
                  exclusions {
                    key
                    values
                  }
                  styles {
                    ...NameParts
                  }
                }
              }
              styles {
                ...NameParts
              }
              items {
                name
                group
                image
                description
                assetImage
                id
                sku
                parentSku
                price
                priceCategory
                exclusions {
                  key
                  values
                }
                styles {
                  ...NameParts
                }
              }
            }
          }
        }
        configuration {
          configId
          sku
          price
          id
          image
          type
          font
          language
          priceCategory
          specification
          exclusions {
            key
            values
          }
          description
          assetImage
          group
          name
          value
        }
        total {
          discount
          itemPrice
          onSale
          salesPrice
        }
      }
      total {
        regularTotal
        promotionTotal
      }
      items
    }
  }
`;

const ADD_TO_CART_MUTATION = gql`
  mutation addToCart($input: AddToCartInput!) {
    addToCart(input: $input)
  }
`;

const REMOVE_FROM_CART_MUTATION = gql`
  mutation removeFromCart($input: CartIdInput!) {
    removeFromCart(input: $input)
  }
`;

const DUPLICATE_PRODUCT_MUTATION = gql`
  mutation duplicateCartProduct($input: CartIdInput!) {
    duplicateCartProduct(input: $input)
  }
`;

const GET_PRODUCT_TOTAL = gql`
  mutation getProductTotal(
    $productId: ID!
    $configuration: [ConfigurationInput]!
  ) {
    getProductTotal(productId: $productId, configuration: $configuration) {
      salesPrice
      discount
      itemPrice
      onSale
    }
  }
`;

const SET_SHIPPING_METHOD_MUTATION = gql`
  mutation setShippingMethod($id: ID!) {
    setShippingMethod(id: $id)
  }
`;

const SET_PAYMENT_METHOD_MUTATION = gql`
  mutation setPaymentMethod($id: ID!) {
    setPaymentMethod(id: $id)
  }
`;

const withAddToCart = graphql(ADD_TO_CART_MUTATION, {
  name: "addToCart"
});
const withSetShippingMethod = graphql(SET_SHIPPING_METHOD_MUTATION, {
  name: "setShippingMethod"
});
const withSetPaymentMethod = graphql(SET_PAYMENT_METHOD_MUTATION, {
  name: "setPaymentMethod"
});
const withRemoveFromCart = graphql(REMOVE_FROM_CART_MUTATION, {
  name: "removeFromCart"
});
const withDuplicateProduct = graphql(DUPLICATE_PRODUCT_MUTATION, {
  name: "duplicateProduct"
});
const withProductTotal = graphql(GET_PRODUCT_TOTAL, {
  name: "getProductTotal"
});

const withCart = graphql(CART_QUERY, {
  props: ({ data }) => ({ ...data })
});

export default compose(
  withCart,
  withAddToCart,
  withRemoveFromCart,
  withDuplicateProduct,
  withProductTotal,
  withSetShippingMethod,
  withSetPaymentMethod
)(CartContext);
