import React, { Fragment } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { gql } from 'apollo-boost';
import serializeForm from 'form-serialize';
import { omit } from 'ramda';
import {
  ButtonText,
  Billboard,
  Button,
  variables,
} from '@lucio-erasmus/sterns-components';
import { siteConfig } from '../../helpers/config';
import {
  getCanvasBlob,
  dataURItoBlob,
  getObjectPos,
} from '../../helpers/utils';

const customStyles = {
  overlay: {
    zIndex: 2000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
    borderRadius: 0,
    minWidth: '200px',
    maxWidth: '95%',
  },
};

class WishlistContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      loading: false,
      error: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  static contextTypes = {
    getProduct: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, sent: false });
  }

  submitForm = e => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const values = serializeForm(e.target, { hash: true });

    const {
      configuration,
      product: { id: productId },
    } = this.props;

    const p = this.context.getProduct();

    if (p.some(config => config.type === 'upload')) {
      let canvasJSON;
      if (window.the_canvas) {
        canvasJSON = window.the_canvas.toJSON();
        window.the_canvas.discardActiveObject();
        window.the_canvas.renderAll();
      }

      return Promise.resolve(p)
        .then(config => {
          const upload = config.find(p => p.type === 'upload');
          const canvas = document.getElementById('main-canvas');

          return Promise.all([
            dataURItoBlob(upload.image),
            upload.original,
            getCanvasBlob(canvas),
            config,
          ]);
        })
        .then(([upload, original, canvas, config]) => {
          upload.name = 'upload.png';
          canvas.name = 'canvass.png';

          const imgPos = getObjectPos(canvasJSON);

          return this.props.saveProduct({
            variables: {
              input: {
                productId,
                ...values,
                template: 'sternsWishlist',
                files: [upload, canvas, original],
                imagePosition: imgPos,
                configuration: [
                  ...config.map(p =>
                    omit(
                      [
                        '__typename',
                        'configId',
                        'styles',
                        'image',
                        'original',
                        'exclusions',
                        'specs',
                      ],
                      p
                    )
                  ),
                ],
              },
            },
          });
        })
        .then(() => {
          this.setState({
            sent: true,
            loading: false,
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            error: err,
          });
        });
    }

    return this.props
      .saveProduct({
        variables: {
          input: {
            productId,
            ...values,
            template: this.props.template,
            configuration: [
              ...configuration.map(p =>
                omit(
                  [
                    '__typename',
                    'configId',
                    'styles',
                    'image',
                    'original',
                    'exclusions',
                    'specs',
                  ],
                  p
                )
              ),
            ],
          },
        },
      })
      .then(data => {
        this.setState({
          sent: true,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err,
        });
      });
  };

  renderSave() {
    return (
      <div className="form-group row align-items-center">
        <div className="col-8">
          <input
            className="form-control form-control-lg"
            type="text"
            name="email"
            placeholder="Your Email Address"
          />
        </div>
        <div className="col-4">
          <Button
            disabled={this.state.loading}
            full
            outline
            color="white"
            size="medium"
          >
            {this.state.loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </div>
    );
  }

  renderHint() {
    return (
      <div className="row">
        <div className="form-group col-md-12">
          <input
            className="form-control form-control-lg"
            type="text"
            name="fromName"
            placeholder="Your Name"
          />
        </div>
        <div className="form-group col-md-6">
          <input
            className="form-control form-control-lg"
            type="text"
            name="name"
            placeholder="Recipient's Name"
          />
        </div>
        <div className="form-group col-md-6">
          <input
            className="form-control form-control-lg"
            type="text"
            name="email"
            placeholder="Recipient's Email"
          />
        </div>
        <div className="form-group col-md-12">
          <textarea
            className="form-control form-control-lg"
            name="message"
            placeholder="Message (optional)"
          />
        </div>
        <div className="form-group col-md-12">
          <Button
            disabled={this.state.loading}
            full
            outline
            color="white"
            size="medium"
          >
            {this.state.loading ? 'Loading' : 'Send'}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <ButtonText
          onClick={this.openModal}
          size="medium"
          text
          iconBefore={this.props.icon}
          mr={variables.spacing.space2}
        >
          {this.props.text}
        </ButtonText>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <Billboard
            small={true}
            border={true}
            title={this.props.modalTitle}
            description={
              !this.state.sent ? this.props.modalCopy : this.props.modalSuccess
            }
          >
            {!this.state.sent ? (
              <form className="dark" onSubmit={this.submitForm}>
                {this.props.template === "hint"
                  ? this.renderHint()
                  : this.renderSave()}
              </form>
            ) : null}
          </Billboard>
        </Modal>
      </Fragment>
    );
  }
}

WishlistContainer.defaultProps = {
  template: 'sternsWishlist',
  icon: 'save',
  text: siteConfig.saveText,
  modalTitle: siteConfig.saveHeadline,
  modalCopy: siteConfig.saveCopy,
  modalSuccess: siteConfig.saveSuccess,
};

const SAVE_PRODUCT_MUTATION = gql`
  mutation saveProduct($input: SaveProductInput!) {
    saveProduct(input: $input)
  }
`;
const withSaveProduct = graphql(SAVE_PRODUCT_MUTATION, { name: 'saveProduct' });

export default compose(withSaveProduct)(WishlistContainer);
