import React from "react";
import { Copy } from "@lucio-erasmus/sterns-components";

const CheckboxField = ({
  id,
  className,
  error,
  label,
  type,
  placeholder,
  description,
  ...input
}) => {
  return (
    <div className={className}>
      <div className={`form-check`}>
        <input
          name={id}
          placeholder={placeholder}
          type={type}
          className="form-check-input"
          {...input}
        />
        <label className="form-check-label">{label}</label>
      </div>
      {error && (
        <Copy mb="0" color="error" size="small">
          {error}
        </Copy>
      )}
      {!error && description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

CheckboxField.defaultProps = {
  className: "col-md-6 form-group"
};

export default CheckboxField;
