export function uploadToS3(file) {
  return getSignedRequest(file)
    .then(json => uploadFile(file, json.signedRequest, json.url))
    .then(url => {
      return url;
    })
    .catch(err => {
      console.error(err);
      return null;
    });
}

function uploadFile(file, signedRequest, url) {
  const options = {
    method: "PUT",
    body: file,
    mode: "cors",
    headers: {
      "Content-Type": file.type,
      "Access-Control-Expose-Headers": "etag"
    }
  };

  return fetch(signedRequest, options).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return url;
  });
}

function getSignedRequest(file) {
  return fetch(
    `${process.env.REACT_APP_API}/s3?fileName=${file.name}&fileType=${file.type}`
  ).then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  });
}
