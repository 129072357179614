import React from "react";
import { Copy } from "@lucio-erasmus/sterns-components";

const SelectField = ({
  id,
  className,
  error,
  label,
  options,
  description,
  inputOptions = {},
  ...input
}) => {
  return (
    <div className={`${className} form-group`}>
      <label>{label}</label>
      <select name={id} className="form-control" {...input} {...inputOptions}>
        <option />
        {options.map(o => (
          <option value={o.value} key={o.key}>
            {o.label}
          </option>
        ))}
      </select>
      {error && (
        <Copy mb="0" color="error" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

SelectField.defaultProps = {
  className: "col-md-6 form-group"
};

export default SelectField;
