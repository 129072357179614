const site_id = process.env.REACT_APP_SITE_ID || "asj";

const config = {
  Sterns: {
    appointmentTitle: "Book an Appointment",
    appointmentText:
      "If you need assistance with your design or would like to book a consultation with our design team fill in the form below and we will contact you directly to arrange a suitable date and time.",
    bespoke:
      "If you haven't found exactly what you're looking for, our team of experts can help you bring your dream ring to life. <a class='link-style' target='_blank' rel='noopenner norefferer' href='/design-your-own-ring/bespoke-design-studio/'>Find out more.</a>",
    threeD:
      "<strong>Disclaimer:</strong> 3D model is a Graphic technical Representation",
    site: "STERNS",
    addToCart: "Add to bag",
    updateCart: "Update Product",
    finePrint:
      "View the terms & conditions for the delivery times and other details relating to your selected items.",
    viewMore: "Discover",
    saveText: "Save",
    saveHeadline: "View it later.",
    saveCopy:
      "Enter your email address to receive a link to view your saved product.",
    saveSuccess: "Your link has been sent to your email.",
    hintText: "Hint",
    hintHeadline: "Drop a hint.",
    hintCopy: "Tell your loved one what’s on your wish list.",
    hintSuccess: "Your hint has been sent to the recipient.",
    backToLink: "http://www.sterns.co.za",
    backToText: "Back to Sterns website",
    newsletterHeadline: "BECOME PART OF THE FAMILY",
    newsletterCopy:
      "Stay up to date with the perfect gifts for family and friends<br>by signing up to our newsletter.",
    newsletterConfirmation: "THANK YOU FOR SUBSCRIBING",
    bespokeConfirmation:
      "Thank you for your submission. We will get back to you as soon as possible",
    bespokeError: "Something went wrong. Please try again later.",
    footerNoticeText:
      "Prices correct at time of publishing.<br/>Merchandise not shown to actual size.",
    footerAttribution:
      'Created by <a target="_blank" href="http://www.plusplusminus.co.za/?utm_source=Sterns&utm_medium=Footer&utm_campaign=Personalisation">PlusPlusMinus</a>',
    footerLegalText:
      "©2018 Use of this Web site constitutes acceptance of Sterns Terms and Conditions and Privacy Policy.",
    footerLinks: [
      {
        label: "Contact Us",
        url: "http://www.sterns.co.za/contactus"
      },
      {
        label: "Store Locator",
        url: "http://www.sterns.co.za/storelocator"
      },
      {
        label: "Careers",
        url: "https://www.tfgcareers.co.za/"
      },
      {
        label: "Privacy Policy",
        url: "https://www.tfg.co.za/privacy-statement"
      },
      {
        label: "Terms & Conditions",
        url:
          "http://www.sterns.co.za/content/pdf/tfg_website_terms_conditions.pdf"
      },
      {
        label: "Open An Account",
        url: "https://www.tfg.co.za/foschinigrp/page/en/tfg-web/select-store"
      },
      {
        label: "Rewards",
        url: "http://www.tfg.co.za/rewards-and-more"
      },
      {
        label: "About Sterns",
        url: "http://www.sterns.co.za/aboutsterns"
      },
      {
        label: "CSI",
        url: "http://www.sterns.co.za/giveback"
      }
    ],
    footerLinksSocial: [
      {
        iconName: "facebook",
        label: "Facebook",
        url: "http://www.facebook.com/SternsJewellery"
      },
      {
        iconName: "instagram",
        label: "Instagram",
        url: "http://instagram.com/sterns_sharejoy/"
      },
      {
        iconName: "youtube",
        label: "YouTube",
        url: "http://www.youtube.com/mysterns"
      }
    ]
  }
};

export const siteConfig = {
  ...config.sterns,
  ...config[site_id]
};
