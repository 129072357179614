import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Copy } from "@lucio-erasmus/sterns-components";

import "react-datepicker/dist/react-datepicker.css";

const DateField = ({
  id,
  className,
  error,
  label,
  type,
  placeholder,
  description,
  ...input
}) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className={`${className} form-group`}>
      <label>{label}</label>
      <div>
        <DatePicker
          name={id}
          selected={startDate}
          onChange={date => setStartDate(date)}
          className="form-control"
        />
      </div>
      {error && (
        <Copy mb="0" color="error" size="small">
          {error}
        </Copy>
      )}
      {!error && description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

export default DateField;
