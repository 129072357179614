import React from 'react';
import { graphql, compose } from 'react-apollo';
import { gql } from 'apollo-boost';
import * as R from 'ramda';
import { siteConfig } from '../../helpers/config';
import { SelectField } from '../../components';

class StoreLocationContainer extends React.Component {
  state = {
    province: null,
    store: null,
    stores: [],
  };

  getProvinces(stores) {
    const { province } = this.state;

    var byCountry = R.groupBy(store => {
      if (store.country.toLowerCase().trim() === 'south africa') {
        return 'ZA';
      } else {
        return 'International';
      }
    });

    var byIntCountry = R.groupBy(store => {
      return store.country;
    });

    var byProvince = R.groupBy(store => {
      return store.province;
    });

    const storesByCountry = byCountry(stores) || [];
    const storesByProvince = byProvince(storesByCountry['ZA'] || []) || [];
    const internationalStores = byIntCountry(
      storesByCountry['International'] || []
    );

    let filter = Object.keys(storesByProvince).sort();

    filter = filter.concat(Object.keys(internationalStores).sort());

    let filteredStores = stores;
    if (province) {
      filteredStores = stores.filter(s => s.province === province);
    }

    return {
      filter,
      filteredStores,
    };
  }

  render() {
    const { loading, stores, field, fieldError } = this.props;
    if (loading) return null;

    const { filter, filteredStores } = this.getProvinces(stores);
    const inputOptions = {
      onChange: e => this.setState({ province: e.target.value }),
    };

    return (
      <div className="row">
        <SelectField
          inputOptions={inputOptions}
          label="Province/Country"
          className="col-md-4"
          options={filter.map(s => ({ label: s, value: s }))}
        />
        <SelectField
          {...field}
          error={fieldError}
          className="col-md-8"
          options={filteredStores.map(s => ({
            label: s.name,
            value: s.branch,
          }))}
        />
      </div>
    );
  }
}

const GET_STORES = gql`
  query getStores($company: String!, $province: String) {
    stores(company: $company, province: $province) {
      branch
      name
      province
      country
    }
  }
`;

const withGetStores = graphql(GET_STORES, {
  options: props => {
    return {
      variables: {
        company: siteConfig.site,
      },
    };
  },
  props: ({ data }) => ({ ...data }),
});

export default compose(withGetStores)(StoreLocationContainer);
