import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import parse from "html-react-parser";
const Seo = ({ metaDesc, fullHead }) => {
  return (
    <Helmet>
      <meta name="description" content={metaDesc} />
      {fullHead && parse(fullHead)}
    </Helmet>
  );
};

Seo.propTypes = {
  metaDesc: PropTypes.string.isRequired,
  fullHead: PropTypes.string.isRequired
};

export default Seo;
