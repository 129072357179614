import React ,{ useState } from "react";
import Accordion from "../Accordion/Accordion";

function MobileMenu({menu}) {
  const [showSidebar, setShowSidebar] = useState(false);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  function closeSidebar() {
    setShowSidebar(false);
  }

  return (
    <div className="mobile-menu" style={{ height: "50px" }}>
      <button className="mobile-menu-button" onClick={toggleSidebar}>
        <span>Menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24"
          height="24"
        >
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
      {showSidebar && (
        <div className="mobile-menu-sidebar">
          <button className="mobile-menu-close-button" onClick={closeSidebar}>
            <span style={{fontSize: '14px'}}>Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="25"
              height="40"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                d="M7 16l5-5 5 5"
              />
            </svg>
          </button>
          <Accordion menu={menu} toggleSidebar={toggleSidebar} />
        </div>
      )}
    </div>
  );
}

export default MobileMenu;
