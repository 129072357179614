import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { gql } from 'apollo-boost';
import { omit } from 'ramda';
import { Loading } from '@lucio-erasmus/sterns-components';
import { formatPrice } from '../../helpers/utils';

class ProductTotal extends React.Component {
  static contextTypes = {
    getProduct: PropTypes.func.isRequired,
  };

  state = {
    total: '',
    loading: true,
    isFromPrice: true,
  };

  componentDidMount() {
    const product = this.props.configuration;

    this.props
      .getProductTotal({
        variables: {
          productId: this.props.productId,
          configuration: [
            ...product.map(p =>
              omit(
                [
                  '__typename',
                  'configId',
                  'styles',
                  'image',
                  'original',
                  'exclusions',
                  'specs',
                  "parentSku"
                ],
                p
              )
            ),
          ],
        },
      })
      .then(result => {
        this.setState({
          loading: false,
          total: result.data.getProductTotal,
        });
        if (this.props.isFrom) {
          this.props.isFrom(result.data.getProductTotal.isFromPrice);
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.configuration !== this.props.configuration) {
      this.setState({
        loading: true,
      });
      this.props
        .getProductTotal({
          variables: {
            productId: this.props.productId,
            configuration: [
              ...this.props.configuration.map(p =>
                omit(
                  [
                    '__typename',
                    'configId',
                    'styles',
                    'image',
                    'original',
                    'exclusions',
                    'specs',
                    "parentSku"
                  ],
                  p
                )
              ),
            ],
          },
        })
        .then(result => {
          this.setState({
            total: result.data.getProductTotal,
            loading: false,
          });
          if (this.props.isFrom) {
            this.props.isFrom(result.data.getProductTotal.isFromPrice);
          }
        });
    }
  }

  render() {
    if (!this.state.total || this.state.loading)
      return <Loading minHeight={false} />;
    const { onSale, salesPrice, itemPrice } = this.state.total;

    if (onSale) {
      return (
        <React.Fragment>
          <span class="sale">Was R{formatPrice(itemPrice)}</span>
          <br />
          <p>
            <strong>Now R{formatPrice(salesPrice)}</strong>
          </p>
        </React.Fragment>
      );
    }

    return <p>R{formatPrice(itemPrice)}</p>;
  }
}

const GET_PRODUCT_TOTAL = gql`
  mutation getProductTotal(
    $productId: ID!
    $configuration: [ConfigurationInput]!
  ) {
    getProductTotal(productId: $productId, configuration: $configuration) {
      salesPrice
      discount
      itemPrice
      onSale
      isFromPrice
    }
  }
`;

const withProductTotal = graphql(GET_PRODUCT_TOTAL, {
  name: 'getProductTotal',
});

export default compose(withProductTotal)(ProductTotal);
