import React, { useEffect, useState } from "react";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import { StyleSheet, css } from "aphrodite";
import queryString from "query-string";
import {
  variables,
  Section,
  Heading,
  Hero,
  Loading,
  Copy,
  Button
} from "@lucio-erasmus/sterns-components";

const styles = StyleSheet.create({
  row: {
    marginTop: variables.spacing.space3,
    marginBottom: variables.spacing.space3
  }
});

const OrderSnapScan = props => {
  if (props.loading) return <Loading />;
  const [load, setLoadingPayment] = useState(false);
  const [error, setError] = useState("");
  const { page: p, order } = props;

  const query = {
    deliveryAddress: "",
    amount: parseFloat(order.total) * 100,
    id: order.orderNumber,
    strict: false
  };

  const checkPayment = async () => {
    setLoadingPayment(true);

    await props.refetch();

    setLoadingPayment(false);
  };

  useEffect(() => {
    if (order.status === "paid") {
      props.history.push(`/order/${order._id}`);
    } else if (order.status === "error") {
      setError("Error processing payment. Please try again.");
    }
  }, [order.status]);

  return (
    <>
      <Hero
        title={p.headline || p.title}
        description={p.content}
        image={p.image}
        small
      />
      <Section mt="small">
        <div className="col-md-6">
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : null}
          <Copy mb={"none"}>Order Number: {order.orderNumber}</Copy>
          <Copy mb={"none"}>Date: {order.formatDate}</Copy>
          <Copy>Payment Method: {order.payment}</Copy>

          <div className={css(styles.row)}>
            <Heading sans size="medium" weight="bold" mt={"small"} mb={"small"}>
              Order Total: R{order.totals}
            </Heading>
          </div>
          <Button onClick={checkPayment}>
            {load ? "Loading" : "Click here when paid"}
          </Button>
        </div>
        <div className="col-md-6 mt-3 mt-md-0 text-left text-md-center">
          <Copy mb="none">
            Tap or Scan the QR code below to make your Snap Scan payment
          </Copy>

          <a
            href={`https://pos.snapscan.io/qr/${
              process.env.REACT_APP_SNAPSCAN
            }?${queryString.stringify(query, { arrayFormat: "bracket" })}`}
          >
            <img
              alt="Snapscan QR code"
              className="img-fluid"
              src={`https://pos.snapscan.io/qr/${
                process.env.REACT_APP_SNAPSCAN
              }.svg?snap_code_size=150&${queryString.stringify(query, {
                arrayFormat: "bracket"
              })}`}
            />
          </a>
        </div>
      </Section>
    </>
  );
};

const PAGE_QUERY = gql`
  query getProduct($slug: String!, $orderId: ID!) {
    page(slug: $slug) {
      id
      title
      content
      excerpt
      headline
      image {
        url
        hotspot
        hasImage
      }
    }
    order(id: $orderId) {
      _id
      totals
      total
      orderNumber
      status
      details {
        name
        surname
        email
        phone
      }
      store {
        name
        address
        email
        branch
      }
      payment
      paymentType
      formatDate
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: "thank-you",
        orderId: props.match.params.orderId
      },
      pollInterval: 5000
    };
  },
  props: ({ data }) => ({ ...data })
});

export default compose(withPage)(OrderSnapScan);
