import React, { useContext } from "react";
import {
  PBody,
  PHeader,
  ButtonText,
  Copy,
  Button,
  Border
} from "@lucio-erasmus/sterns-components";
import { ProductContext } from "../Store/CartContext";

const AdditionalComponent = props => {
  const {
    active,
    errors,
    config: c = {},
    onEnter,
    onLeave,
    onAdditionalToggle = () => {}
  } = props;
  const { getProduct, onProductDelete, onProductAdd } = useContext(
    ProductContext
  );

  const onAdditional = () => {
    onProductAdd({
      sku: c.sku,
      parent: c.parentSku,
      specs: {
        wbShape: "STRAIGHT",
        wbStyle: "DIAMOND"
      }
    });

    onAdditionalToggle();
  };

  let key = "sku";
  if (c.parentSku) {
    key = "parentSku";
  }
  const hasProduct = getProduct().some(product => {
    return product.sku === c[key];
  });

  if (hasProduct) {
    let headingProps = {};

    if (hasProduct) {
      headingProps.icon = "done";
    }

    if (active) {
      headingProps = {
        hover: true,
        icon: hasProduct ? "edit" : "angle-right"
      };
    }

    return (
      <PHeader
        title={c.name}
        onClick={onAdditionalToggle}
        icon="angle-right"
        onEnter={onEnter}
        onLeave={onLeave}
        {...headingProps}
      >
        <ButtonText
          iconBefore={"close"}
          onClick={() => {
            onProductDelete({
              sku: c.parentSku || c.sku
            });
          }}
        >
          Remove {c.group}
        </ButtonText>
        {errors[c.sku] ? (
          <div className="w-100">
            <Copy size="tiny" mb="none" color={"error"}>
              {" "}
              {JSON.stringify(errors[c.sku])}
            </Copy>
          </div>
        ) : null}
      </PHeader>
    );
  }

  return (
    <React.Fragment>
      <Border mt="none" />
      <PBody hasPadding>
        <Button size="medium" full outline onClick={onAdditional}>
          Add {c.group}
        </Button>
      </PBody>
    </React.Fragment>
  );
};

export default AdditionalComponent;
