import React from "react";
import PropTypes from "prop-types";
import { FormHeading } from "@lucio-erasmus/sterns-components";
import { RadioField } from "../../components";

const PaymentContainer = ({ errors = {}, options = [] }, context) => {
  const { cart, setPaymentMethod } = context;
  const { payment_method = "PAYU" } = cart();

  return (
    <div className="col-md-12">
      <FormHeading>Payment Method</FormHeading>
      <div className="pt-2 pb-2 row">
        <RadioField
          id={"payment"}
          onChange={e => {
            setPaymentMethod(e.target.id);
          }}
          value={payment_method}
          options={options.map(method => ({
            label: `${method.label}`,
            value: method.value
          }))}
          error={errors.payment}
        />
      </div>
    </div>
  );
};

PaymentContainer.contextTypes = {
  cart: PropTypes.func,
  setPaymentMethod: PropTypes.func
};

export default PaymentContainer;
