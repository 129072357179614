import React, { useState, useLayoutEffect } from "react";
import { graphql } from "react-apollo";
import { gql } from "apollo-boost";
import {
  Border,
  Loading,
  Copy,
  Heading,
  StoreFrontHeader
} from "@lucio-erasmus/sterns-components";
import { NewsletterContainer } from "..";
import Helmet from "react-helmet";
import { getDYORPage } from "../../helpers/wp";
import Media from "react-media";

const DYORContainer = props => {
  const [page, setPage] = useState({ loading: true, page: null });
  useLayoutEffect(() => {
    setPage({
      loading: true,
      page: null
    });
    getDYORPage(props.match.params.slug).then(data => {
      setPage({
        loading: false,
        page: data
      });
    });
  }, [props.match.params.slug]);
  if (page.loading) {
    return <Loading />;
  }
  const { page: p } = page;

  return (
    <>
      <Helmet
        defaultTitle={p.title}
        meta={[
          { name: "description", content: p.excerpt },
          { name: "og:title", content: p.title },
          { name: "og:description", content: p.excerpt },
          {
            name: "og:url",
            content: `https://personalise.americanswiss.co.za/${p.slug}`
          },
          { name: "og:site_name", content: `Sterns` },
          { name: "og:type", content: `website` },
          {
            name: "og:image",
            content: p.featured_media ? p.featured_media.source_url : ""
          }
        ]}
        htmlAttributes={{ lang: "en" }}
      />
      {
        <StoreFrontHeader
          image={
            p.page && p.DYOR.bannerImageMobile.mediaItemUrl
              ? p.DYOR.bannerImageMobile.mediaItemUrl
              : p.DYOR.bannerImageMobile.mediaItemUrl
          }
        />
      }
      {p.DYOR.headlineImage ? (
        <div className="text-center" style={{ margin: "auto" }}>
          <img
            style={{ height: "100px", width: "auto" }}
            alt={p.headline ? p.headline : p.title}
            className="img-fluid pl-3 pr-3 py-3"
            src={p.DYOR.headlineImage.mediaItemUrl}
          />
        </div>
      ) : (
        <div className="p-3">
          <Heading center size={"large"} mb={"small"} html={p.title} />
        </div>
      )}
      <div className="container entry">
        <div className="row ">
          {p.content && (
            <div className="col-10 m-auto">
              <Copy html={p.content} mb={"large"} size="large" />
            </div>
          )}

          <div className="col-10 m-auto">
            <div className="row">
              {p.DYOR.imageMenu.map((i, index) => {
                return (
                  <div className="col-6" key={index}>
                    <a href={i.itemLink} title={i.itemLink}>
                      <Media query="(min-width: 576px)">
                        {matches =>
                          matches ? (
                            <img
                              alt={i.itemName}
                              className="img-fluid"
                              src={i.itemImage.mediaItemUrl}
                            />
                          ) : (
                            <img
                              alt={i.itemName}
                              className="img-fluid"
                              src={i.itemImageMobile.mediaItemUrl}
                            />
                          )
                        }
                      </Media>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Border color="white" />
      </div>
      <NewsletterContainer />
    </>
  );
};

const PAGE_QUERY = gql`
  query getProduct($slug: String!) {
    page(slug: $slug) {
      id
      title
      content
      excerpt
      description
      subtitle
      headline
      image: featured_media {
        source_url
      }
      heroImage: image {
        url
        hotspot
      }
      featured_media {
        source_url
      }
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: "design-your-own-ring"
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withPage(DYORContainer);
