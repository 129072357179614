import React from "react";
import PropTypes from "prop-types";
import {
  FormHeading,
  Border,
  Heading,
  Loading
} from "@lucio-erasmus/sterns-components";
import { RadioField } from "../../components";
import StoreLocationContainer from "./StoreLocationContainer";
import FormContainer from "./FormContainer";
import { formatPrice } from "../../helpers/utils";

const ShippingContainer = ({ errors = {} }, context) => {
  const {
    loading,
    cart,
    setShippingDetails,
    shippingDetails,
    getShippingMethods,
    setShippingMethod
  } = context;

  const onSetValues = (key, value) => {
    setShippingDetails({ [key]: value });
  };

  const shippingMethods = getShippingMethods();
  const { shipping_method } = cart();

  const onSelect = payload => {
    setShippingDetails(payload.formattedAddressObject);
  };

  const hasOption = shippingMethods.some(
    method => method.id === shipping_method
  );
  return (
    <div className="col-md-12">
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: " 0",
            background: "rgba(255,255,255,0.8)",
            zIndex: 1050,
            paddingTop: "20px"
          }}
        >
          <Loading minHeight={false} />
        </div>
      ) : null}
      <FormHeading>Select Shipping Method</FormHeading>
      <div className="pt-2 pb-2 row">
        <RadioField
          id={"delivery"}
          disabled={loading}
          onChange={e => {
            setShippingMethod(e.target.id);
          }}
          value={shipping_method}
          options={shippingMethods.map(method => ({
            label: `${method.description} - ${
              method.price ? `R ${formatPrice(method.price)}` : "Free"
            }`,
            value: method.id
          }))}
          error={errors.delivery}
        />
      </div>

      {shipping_method === "collection" ? (
        <div>
          <Border mb="small" />
          <Heading mb="tiny" sans size="small" uppercase>
            Click and Collect
          </Heading>

          <label>
            Free collection from over 400 TFG selected stores. We’ll notify you
            when your order is ready for collection.
          </label>
          <StoreLocationContainer
            fieldError={errors["shipping.store"]}
            field={{
              onChange: e => onSetValues("shipping.store", e.target.value),
              value: shippingDetails["shipping.store"],
              name: "shipping.store"
            }}
          />
        </div>
      ) : hasOption ? (
        <div>
          <Border mb="small" />
          <Heading mb="tiny" sans size="small" uppercase>
            Deliver to this address
          </Heading>

          <label size="small" color="dark">
            Free standard delivery for orders over R500. We might be sourcing
            your items from different stores so you could receive separate
            parcels and notifications.
          </label>
          <FormContainer
            as={"div"}
            formId="shipping"
            onChange={onSetValues}
            values={shippingDetails}
            onSelect={onSelect}
            errors={errors}
          />
        </div>
      ) : null}
    </div>
  );
};

ShippingContainer.contextTypes = {
  cart: PropTypes.func,
  loading: PropTypes.bool,
  setShippingDetails: PropTypes.func,
  shippingDetails: PropTypes.any,
  getShippingMethods: PropTypes.func,
  setShippingMethod: PropTypes.func
};

export default ShippingContainer;
