import React, { Component } from "react";
import PropTypes from "prop-types";

import { ButtonText, Navbar, Loading } from "@lucio-erasmus/sterns-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Sticky from "react-stickynode";

class MainNavContainer extends Component {
  static contextTypes = {
    cart: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        open: false,
        isDropdownOpen: false
      });
      window.scrollTo(0, 0);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      showCondensed: false,
      open: false,
      fly: null,
      isDropdownOpen: false,
      activeIndex: 0,
      items: []
    };
  }

  handleStateChange = status => {
    if (status.status === Sticky.STATUS_FIXED) {
      this.setState({
        showCondensed: true
      });
    } else {
      this.setState({
        showCondensed: false
      });
    }
  };

  toggleMenu = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  openDropdown = (index, items) => {
    this.setState({
      isDropdownOpen: true,
      activeIndex: index,
      menuItems: items
    });
  };

  closeDropdown = () => {
    this.setState({
      isDropdownOpen: false,
      activeIndex: 0
    });
  };

  render() {
    if (this.props.loading) return <Loading />;
    const { menu } = this.props;
    const { activeIndex } = this.state;

    if (this.props.loading) return null;

    return (
      <>
        <Navbar padding={"10px"} background="#e5e5e5">
          <ButtonText
            target={"_blank"}
            href={"https://www.sterns.co.za/home"}
            iconBefore="arrow-left"
            color="gray"
            iconColor="gray"
          >
            BACK TO STERNS WEBSITE
          </ButtonText>
        </Navbar>
        <header class="header header--logged-in js-tfg-view js-tfg-view-header">
          <div class="header__content">
            <div class="header__inner" id="header-content">
              <span
                href="#menu"
                class="header__inner-control js-header-trigger-menu"
                onClick={() => this.toggleMenu()}
              >
                <i class="header__inner-control-menu"></i>
              </span>
              <a href="https://www.tfg.co.za" class="header__flag">
                <i class="header__flag-icon"></i>
              </a>
              <div class="header__controls">
                <div class="header__controls-container">
                  <div class="header__controls-inner">
                    <a
                      href="/browse/storeLocator.jsp"
                      class="header__control-item header__control-item--desktop js-nav-mobile-toggle-store-locator"
                    >
                      <i class="header__control-item-icon-store"></i>
                      <div class="header__control-label">
                        <span class="header__control-copy header__control-copy--alt">
                          Store Locator
                        </span>
                        <span class="header__control-copy">
                          Find Stores nearby
                        </span>
                      </div>
                    </a>

                    <Link
                      to={"/cart"}
                      className="header__control-item header__control-item--alt js-header-trigger-bag"
                    >
                      <i class="header__control-item-icon-bag"></i>
                      <div class="header__control-label">
                        {this.context &&
                        this.context.cart &&
                        this.context.cart().items ? (
                          <div
                            class="header__control-item-counter js-component-header-mini-cart-count"
                            data-value="mini-cart"
                          >
                            {this.context.cart().items}
                          </div>
                        ) : null}
                        <span class="header__control-copy header__control-copy--alt">
                          Your Bag
                        </span>
                        <span class="header__control-copy">
                          Personalisation Checkout
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <a
                href="https://www.sterns.co.za/home"
                className="header__site-logo"
              >
                <img
                  id="header-logo"
                  src="https://cdn.tfgmedia.co.za/05/Marketing/sterns_2021_logo_wordmarkv2.png"
                  class="header__site-logo-img"
                  alt="Logo"
                />
              </a>
            </div>
          </div>
          <div
            class={`header__nav js-header-component-navigation ${
              this.state.open ? "state--open" : ""
            }`}
            data-type="flyout"
          >
            <div class="header__nav-outer">
              <div class="header__nav-inner js-navigation-component-scroll-area">
                <div class="header__nav-inner-content js-navigation-component-inner-content">
                  <div class="js-header-component-mobile-search"></div>
                  <div class="header__cta-container js-header-component-mobile-account-cta"></div>
                  <nav class="nav-flyout js-component-nav-flyout-content">
                    <a
                      href="#menu"
                      class="nav-flyout__toggle js-component-navigation-menu-toggle"
                    >
                      <i class="nav-flyout__toggle-icon"></i>
                      <div class="nav-flyout__toggle-content">Menu</div>
                    </a>
                    <ul
                      class="nav-flyout__list js-component-navigation-list"
                      style={{
                        width: "auto",
                        overflow: this.state.fly ? "visible" : "hidden"
                      }}
                    >
                      {menu.menu.edges.map(({ node: item }) => {
                        const active = this.state.fly === item.databaseId;
                        return (
                          <li
                            class="nav-flyout__item js-component-navigation-item"
                            data-nav-type="accordion"
                            onMouseEnter={() => {
                              if (!this.state.open) {
                                this.setState({ fly: item.databaseId });
                              }
                            }}
                            onMouseLeave={() => {
                              if (!this.state.open) {
                                this.setState({ fly: 0 });
                              }
                            }}
                            key={item.key}
                          >
                            <div
                              class={`nav-flyout__item-link js-component-navigation-action-container`}
                            >
                              <Link
                                to={`${item.uri}`}
                                class={`nav-flyout__item-title js-component-navigation-action-area ${
                                  active ? "state--toggled" : ""
                                }`}
                                onClick={e => {
                                  if (this.state.open) {
                                    e.preventDefault();
                                    this.setState(state => ({
                                      fly:
                                        state.fly === item.databaseId
                                          ? 0
                                          : item.databaseId
                                    }));
                                  }
                                }}
                              >
                                <span class="nav-flyout__item-title-copy">
                                  {item.name}
                                </span>
                              </Link>
                              <div
                                class={`nav-flyout__sub js-component-navigation-action-target ${
                                  active ? "state--active" : ""
                                }`}
                              >
                                <div class="nav-flyout__sub-list-wrapper">
                                  <div class="nav-flyout__sub-list-title">
                                    {item.name}
                                  </div>
                                  {item.children &&
                                    item.children.edges.map(
                                      ({ node: child }) => {
                                        return (
                                          <div
                                            class="nav-flyout__sub-list-column"
                                            key={child.key}
                                          >
                                            <div class="nav-flyout__sub-list js-component-navigation-action-container">
                                              <Link
                                                to={child.uri}
                                                class={`nav-flyout__sub-title js-component-navigation-action-area ${
                                                  activeIndex ===
                                                  child.databaseId
                                                    ? "state--toggled"
                                                    : ""
                                                }`}
                                                onClick={e => {
                                                  if (this.state.open) {
                                                    e.preventDefault();
                                                    this.setState(state => ({
                                                      activeIndex:
                                                        state.activeIndex ===
                                                        child.databaseId
                                                          ? 0
                                                          : child.databaseId
                                                    }));
                                                  }
                                                }}
                                              >
                                                <span class="nav-flyout__sub-title-copy">
                                                  {child.name}
                                                </span>
                                              </Link>
                                              <ul
                                                class={`nav-flyout__sub-item-list js-component-navigation-action-target ${
                                                  activeIndex ===
                                                  child.databaseId
                                                    ? "state--active"
                                                    : ""
                                                }`}
                                              >
                                                {child.children &&
                                                  child.children.edges.map(
                                                    ({ node: grandchild }) => {
                                                      return (
                                                        <li
                                                          class="nav-flyout__sub-item"
                                                          key={grandchild.key}
                                                        >
                                                          <Link
                                                            to={grandchild.uri}
                                                            class="nav-flyout__sub-link"
                                                          >
                                                            <span class="nav-flyout__sub-link-copy">
                                                              {grandchild.name}
                                                            </span>
                                                          </Link>
                                                        </li>
                                                      );
                                                    }
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                      <li
                        class="nav-flyout__item js-component-navigation-item"
                        data-nav-type="accordion"
                        key={"cyor"}
                      >
                        <div
                          class={`nav-flyout__item-link js-component-navigation-action-container`}
                        >
                          <Link
                            to={"/design-your-own-ring"}
                            class={`nav-flyout__item-title js-component-navigation-action-area`}
                          >
                            <span class="nav-flyout__item-title-copy">
                              {"Design Your Own Ring"}
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="header__nav-profile js-header-component-mobile-profile-menu"></div>
              </div>

              <div class="header__nav-overlay js-header-component-navigation-toggle"></div>
              <div
                class="header__nav-close js-header-component-navigation-toggle"
                onClick={() =>
                  this.setState({ open: false, activeIndex: 0, fly: 0 })
                }
              ></div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(MainNavContainer);
