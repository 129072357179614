import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Copy,
  PBody,
  PHeader,
  PDescription,
  PModule,
  PTabs,
  PTab,
  POptionText,
  POptionsGrid,
  POptionImage,
} from '@lucio-erasmus/sterns-components';
import ProductSelector from './ProductSelector';

class StylesComponent extends Component {
  state = {
    stone: null,
    item: null,
    active: null,
    activeIndex: null,
  };

  static contextTypes = {
    getProduct: PropTypes.func.isRequired,
    onProductAdd: PropTypes.func.isRequired,
    onProductDelete: PropTypes.func.isRequired,
  };

  findProduct(item) {
    const product = this.context.getProduct();
    return <ProductSelector item={item} product={product} />;
  }

  onItemClick = item => {
    this.context.onProductAdd({
      ...item,
      sku: this.props.sku,
      group: this.props.group,
      price: item.price || this.props.price,
    });
  };

  onDeleteClick = item => {
    const { styles } = this.props;

    this.context.onProductDelete({
      ...item,
      sku: this.props.sku,
      connected: styles.map(s => s.sku),
    });
  };

  mouseEnter = index => {
    this.setState({
      activeIndex: index,
    });
  };

  mouseLeave = () => {
    this.setState({
      activeIndex: null,
    });
  };

  showStyle() {
    const { styles, errors = {} } = this.props;
    const product = this.context.getProduct();
    const activeItem = this.context
      .getProduct()
      .find(i => i.sku === this.props.sku);

    let allStyles = styles.concat(activeItem.styles || []);

    return (
      <div>
        {allStyles.map((o, index) => {
          let headingProps = {};
          const e = product.find(p => p.sku === o.sku);

          if (e) {
            headingProps.icon = 'done';
          }

          if (index === this.state.activeIndex) {
            headingProps = {
              hover: true,
              icon: e ? 'edit' : null,
            };
          }

          return (
            <PHeader
              title={o.name}
              onClick={() => this.props.onSelect({ ...o, parent: this.props })}
              onEnter={() => this.mouseEnter(index)}
              onLeave={() => this.mouseLeave()}
              {...headingProps}
            >
              {this.findProduct(o)}
              {errors[o.sku] ? (
                <div className="w-100">
                  <Copy size="tiny" mb="none" color={'error'}>
                    {' '}
                    {errors[o.sku]}{' '}
                  </Copy>
                </div>
              ) : null}
            </PHeader>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      name,
      description,
      sku,
      hasPadding,
      subtitle,
      className = '',
      groups = [],
      items = [],
      styles,
      onClose,
      errors = {},
      text = false,
    } = this.props;

    const activeItem = this.context
      .getProduct()
      .find(i => i.sku === this.props.sku);

    return (
      <div className={className}>
        <PModule title={name} subtitle={subtitle} parent onClose={onClose}>
          <PBody hasPadding={hasPadding} active>
            {description && <PDescription html={description} />}

            {groups && groups.length ? (
              <PTabs hasMargin>
                {groups.map(g => (
                  <PTab>{g}</PTab>
                ))}
              </PTabs>
            ) : null}
            <POptionsGrid>
              {items.map(i => (
                <Fragment>
                  {text ? (
                    <div
                      className={
                        className
                          ? className
                              .split(' ')
                              .map(r => `${r}--text`)
                              .join(' ')
                          : ''
                      }
                    >
                      <POptionText
                        active={activeItem && activeItem.id === i.id}
                        onClick={() => this.onItemClick(i)}
                        onRemove={() => this.onDeleteClick(i)}
                        title={i.name}
                      >
                        {i.name}
                      </POptionText>
                    </div>
                  ) : (
                    <div
                      className={
                        className
                          ? className
                              .split(' ')
                              .map(r => `${r}--image`)
                              .join(' ')
                          : ''
                      }
                    >
                      <POptionImage
                        active={activeItem && activeItem.id === i.id}
                        onClick={() => this.onItemClick(i)}
                        onRemove={() => this.onDeleteClick(i)}
                        title={i.name}
                        image={`${process.env.REACT_APP_BUILD}${i.assetImage ||
                          i.image}`}
                      />
                    </div>
                  )}
                </Fragment>
              ))}
            </POptionsGrid>
            {activeItem && activeItem.description ? (
              <PDescription html={activeItem.description} />
            ) : null}
            {errors[sku] ? (
              <div className="w-100">
                <Copy size="tiny" mb="none" color={'error'}>
                  {' '}
                  {errors[sku]}{' '}
                </Copy>
              </div>
            ) : null}
          </PBody>
          {activeItem && styles.length && this.showStyle(activeItem)}
        </PModule>
      </div>
    );
  }
}

StylesComponent.defaultProps = {
  hasPadding: false,
};

export default StylesComponent;
