import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { calculateCharLimit } from "../../helpers/utils";

import {
  Heading,
  Copy,
  Icon,
  PBody,
  PDescription,
  PModule,
  POptionsGrid,
  POptionText
} from "@lucio-erasmus/sterns-components";
import { debounce } from "lodash";

class Engravevable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      active: false,
      ...this.getInputOptions(),
      notice: null
    };
  }

  static contextTypes = {
    onProductAdd: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired
  };

  delayedQuery = debounce((input, font) => {
    this.context.onProductAdd({
      type: this.props.type,
      sku: this.props.sku,
      group: this.props.group,
      value: input,
      id: input,
      font,
      specs: {
        [this.props.sku]: input,
        font
      },
      parent: this.props.parentSku
    });
  }, 500);

  getInputOptions() {
    const { parentSku, sku } = this.props;

    const product = this.context.getProduct().find(p => {
      if (parentSku) {
        return p.parent === parentSku && p.specification[sku];
      }

      return p.sku === this.props.sku;
    });

    let initOptions = {
      input: "",
      font: this.props.fonts.length ? this.props.fonts[0].id : null
    };

    if (product) {
      if (parentSku) {
        const { specification = {} } = product;
        initOptions.input = specification[this.props.sku] || "";
        initOptions.font = specification.font || null;
      } else {
        initOptions.input = product.value || "";
        if (product.font) {
          initOptions.font = product.font;
        }
      }
    }

    return initOptions;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sku !== this.props.sku) {
      this.setState({
        ...this.getInputOptions()
      });
      const configuration = this.context.getProduct();
      const engraving = configuration.find(
        config => config.type && config.type === "engrave"
      );
      if (engraving) {
        this.setState({ font: engraving.font });
      }
    }
  }

  firstUpperCase(input) {
    if (input === " ") return "";

    if (input.length) return input[0].toUpperCase() + input.substr(1);

    return input;
  }

  componentDidMount() {
    const configuration = this.context.getProduct();
    const engraving = configuration.find(
      config => config.type && config.type === "engrave"
    );
    if (engraving) {
      this.setState({ font: engraving.font });
    }
    setTimeout(() => {
      this.input.focus();
    }, 200);
  }

  onEngraveChange = value => {
    const {
      limit,
      fonts,
      regEx,
      upcase = false,
      selectedComponents,
      allUpcase,
      additionalLimits
    } = this.props;

    if (
      value.length >
      calculateCharLimit(selectedComponents, additionalLimits, limit)
    )
      return;

    let font;
    if (this.state.font) {
      font = this.state.font;
    } else {
      font = fonts.length ? fonts[0].id : null;
    }

    let input = value;

    if (regEx) {
      switch (regEx) {
        case "keyboard": {
          input = input.replace(/[^A-Za-z0-9!-#%-*,-/:;?@[-]_{}\u2665]+/gi, "");
          break;
        }
        case "lettersOnly": {
          input = input
            .replace(/[^a-zA-Z0-9àáâãäèéêëìíîïòóôõöùúûü&\\-\s]+/g, "")
            .trim();
          break;
        }
        case "letters": {
          input = input.replace(/[^a-zA-Zfe\\-\s]+/g, "");
          break;
        }
        default:
          break;
      }
    }

    if (allUpcase) {
      input = input.toUpperCase();
    }

    if (upcase) {
      input = this.firstUpperCase(input);
    }

    this.setState({
      input
    });

    this.delayedQuery(input, font);
  };

  selectFont = id => {
    const configuration = this.context.getProduct();

    configuration.forEach(config => {
      if (config.type === "engrave") {
        const { specs, font: parentFont, ...rest } = config;
        const { font, ...restOfSpecs } = specs;

        const newConfig = {
          ...rest,
          font: id,
          specs: { ...restOfSpecs, font: id }
        };
        this.context.onProductAdd(newConfig);
      }
    });
    this.setState({ font: id }, () => this.onEngraveChange(this.state.input));
  };

  addChar = char => {
    const currentInput = this.state.input;

    const start = this.input.selectionStart;

    const newInput = [
      currentInput.slice(0, start),
      char,
      currentInput.slice(start)
    ].join("");
    this.onEngraveChange(newInput);

    this.input.focus();
    this.input.setSelectionRange(start, start);
  };

  renderInput() {
    const {
      name,
      limit,
      fonts,
      specialChars,
      description,
      subtitle,
      onClose,
      selectedComponents,
      additionalLimits
    } = this.props;
    const { input, font } = this.state;

    const charLimit = calculateCharLimit(
      selectedComponents,
      additionalLimits,
      limit
    );

    return (
      <Fragment>
        <PModule title={name} subtitle={subtitle} parent onClose={onClose}>
          <PBody active hasPadding>
            {description && <PDescription html={description} />}

            <input
              className="form-control"
              ref={node => (this.input = node)}
              onChange={e => this.onEngraveChange(e.target.value)}
              value={this.state.input}
            />

            <Copy size="tiny">
              {charLimit - input.length} character(s) remaining
            </Copy>

            {fonts.length > 1 && (
              <div className="mt-3">
                <Heading color={"base"} uppercase sans size={"tiny"} mb="tiny">
                  Select a Font
                </Heading>
                <POptionsGrid>
                  {fonts.map((i, index) => (
                    <POptionText
                      key={index}
                      active={font ? i.id === font : false}
                      onClick={() => this.selectFont(i.id)}
                    >
                      {i.title}
                    </POptionText>
                  ))}
                </POptionsGrid>
              </div>
            )}
            {specialChars && specialChars.length > 0 && (
              <div className="mt-3">
                <Heading color={"base"} uppercase sans size={"tiny"} mb="tiny">
                  Special Characters
                </Heading>
                <POptionsGrid>
                  {specialChars.map((i, index) => (
                    <POptionText
                      key={index}
                      onClick={() => this.addChar(i.char)}
                    >
                      {i.type === "icon" ? <Icon name={i.name} /> : i.name}
                    </POptionText>
                  ))}
                </POptionsGrid>
              </div>
            )}
          </PBody>
        </PModule>
      </Fragment>
    );
  }

  render() {
    return this.renderInput();
  }
}

Engravevable.defaultProps = {
  fonts: []
};

export default Engravevable;
