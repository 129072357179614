import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Border,
  Loading,
  Copy,
  Section,
  Card,
  StoreFrontHeader
} from "@lucio-erasmus/sterns-components";
import { NewsletterContainer } from "../../containers";
import { getPage } from "../../helpers/wp";
import { Embed } from "../../components";
import PageNotFound from "./PageNotFound";
import Seo from "../../components/Seo/Seo";

const PageContainer = props => {
  const [p, setPage] = useState({ loading: true, page: null });
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    setPage({
      loading: true,
      page: null
    });
    let uri = props.match.params.parent
      ? `/${props.match.params.parent}/${props.match.params.slug}`
      : `/${props.match.params.slug}`;
    getPage(uri).then(data => {
      setPage({
        loading: false,
        page: data
      });
    });
  }, [props.match.params]);

  if (p.loading) return <Loading />;
  if (!p.page) return <PageNotFound />;
  const { metaDesc, fullHead } = p.page.seo;
  return (
    <>
      <Seo metaDesc={metaDesc} fullHead={fullHead} />
      <div className={`page-${p.page.slug}`}>
      {p.page && p.page.pageOptions && p.page.pageOptions.isFullWidth ? (
        <div dangerouslySetInnerHTML={{ __html: p.page.content }} />
      ) : (
        <>
          {
            <div style={{ color: p.page.headlineOptions.color }}>
              <StoreFrontHeader
                headline={
                  p.page && !p.page.headlineOptions.hideText
                    ? p.page && p.page.headlineOptions.headline
                      ? p.page.headlineOptions.headline
                      : p.page.title
                    : null
                }
                image={
                  p.page &&
                  p.page.heroImageOptions.heroImage &&
                  p.page.heroImageOptions.heroImage.url
                }
                description={
                  p.page && !p.page.headlineOptions.hideText
                    ? p.page && p.page.headlineOptions.description
                    : null
                }
                color={p.page.headlineOptions.color}
              />
            </div>
          }
          <div className="container entry">
            <Copy html={p.page && p.page.content} />
            <Border color="white" />
          </div>
        </>
      )}
      {p.page && p.page.parentDatabaseId === null ? (
        <Section rowClass="row justify-content-center" mb="none">
          {p.page.pageOptions.childPageMenu &&
            p.page.pageOptions.childPageMenu.map(child => {
              const ItemCard = () => {
                return (
                  <Card
                    portrait
                    vertical
                    title={
                      // child.headlineOptions &&  child.headlineOptions.headline ||
                      child.title
                    }
                    description={
                      child.__typename === "Productrange"
                        ? child.excerpt
                        : child.pageOptions && child.pageOptions.excerpt
                    }
                    image={
                      child.__typename === "Productrange"
                        ? {
                            url: child.heroImageOptions.heroImage.sourceUrl,
                            hotspot: ["50%", "50%"]
                          }
                        : child.featuredImage &&
                          child.featuredImage.node.sourceUrl
                        ? {
                            url: child.featuredImage.node.sourceUrl,
                            hotspot: ["50%", "50%"]
                          }
                        : null
                    }
                    eyeCatcher={
                      child.__typename === "Productrange"
                        ? child.rangeOptions.eyecatcher
                        : child.pageOptions && child.pageOptions.eyecatcher
                    }
                  />
                );
              };

              if (child.parentDatabaseId !== null) {
                return (
                  <div className="col-6 col-md-4" key={child.id}>
                    {child.pageOptions && child.pageOptions.linksToBash ? (
                      <a
                        href={child.pageOptions.bashcomDestination}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={child.headlineOptions.headline || child.title}
                      >
                        <ItemCard />
                      </a>
                    ) : (
                      <Link
                        to={
                          child.__typename === "Productrange" &&
                          child.rangeOptions.linkUri
                            ? child.rangeOptions.linkUri
                            : child.rangeOptions && child.rangeOptions.connectedCategory.uri
                            ?child.rangeOptions && child.rangeOptions.connectedCategory.uri.replace(
                                "/sterns",
                                ""
                              ): child.uri
                        }
                      >
                        <ItemCard />
                      </Link>
                    )}
                  </div>
                );
              } else if (
                child.parentDatabaseId === null &&
                child.title !== "Home"
              ) {
                return (
                  <div
                    className={`col-6 col-md-4 key={child.id} card-${child.slug}`}
                  >
                    {child.pageOptions && child.pageOptions.linksToBash ? (
                      <a
                        href={child.pageOptions.bashcomDestination}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={child.headlineOptions.headline || child.title}
                      >
                        <ItemCard />
                      </a>
                    ) : (
                      <Link
                        to={
                          child.__typename === "Productrange"
                            ? child.rangeOptions.linkUri
                            : child.uri
                        }
                      >
                        <ItemCard />
                      </Link>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}

          {p.page.pageOptions.personalisedChildPageMenu &&
            p.page.pageOptions.personalisedChildPageMenu.map(child => {
              
              return (
                <div className="col-6 col-md-4" key={child.id}>
                  <Link to={`/product-category/${child.slug}`}>
                    <Card
                      portrait
                      vertical
                      title={child.name || child.title}
                      description={child.description}
                      image={
                        child.image && child.image.sourceUrl
                          ? {
                              url: child.image.sourceUrl,
                              hotspot: ["50%", "50%"]
                            }
                          : null
                      }
                      eyeCatcher={
                        child.__typename === "ProductCategory"
                          ? child.DYORCategory && child.DYORCategory.eyecatcher
                          : child.pageOptions && child.pageOptions.eyecatcher
                      }
                    />
                  </Link>
                </div>
              );
            })}
        </Section>
      ) : null}
      {p.page && p.page.pageOptions && p.page.pageOptions.publitas ? (
        <Embed src={p.page.pageOptions.publitasUrl} />
      ) : null}
      </div>
      <NewsletterContainer />
    </>
  );
};

export default PageContainer;
