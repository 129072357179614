import React from "react";
import { graphql } from "react-apollo";
import { gql } from "apollo-boost";
import serializeForm from "form-serialize";
import {
  RadioField,
  SelectField,
  TextAreaField,
  TextField,
  UploadField,
  DateField,
  FileField
} from "../../components";
import StoreLocationContainer from "./StoreLocationContainer";
import LocationSearchInput from "./LocationSearchInput";
import { FormHeading, FormBox } from "@lucio-erasmus/sterns-components";
import ShippingContainer from "./ShippingContainer";
import CheckboxField from "../../components/Form/CheckboxField";
import RingImage from "../Ring/ui/RingImage";
import PaymentContainer from "./PaymentContainer";

class FormContainer extends React.Component {
  state = {
    errors: [],
    sent: false,
    error: null,
    loading: false,
    file: null
  };

  getGroupField(group) {
    return (
      <div className={group.className} key={group.id}>
        {group.label ? (
          <FormHeading>{group.label}</FormHeading>
        ) : (
          <div style={{ marginTop: "16px" }}></div>
        )}
        {group.box ? (
          <FormBox>
            <div className="row">
              {group.fields.map(field => {
                if (field.box) {
                  return <FormBox>{this.getField(field)}</FormBox>;
                }
                return this.getField(field);
              })}
            </div>
          </FormBox>
        ) : (
          <React.Fragment>
            {group.fields.map(field => {
              if (field.box) {
                return <FormBox>{this.getField(field)}</FormBox>;
              }
              return this.getField(field);
            })}
          </React.Fragment>
        )}
      </div>
    );
  }

  getField(field) {
    const { errors } = this.state;

    let _field = { ...field, error: errors[field.id] };

    if (this.props.onChange) {
      _field.onChange = e => this.props.onChange(e.target.name, e.target.value);
      _field.value = this.props.values[field.id];
      _field.error = this.props.errors[field.id];
    }

    switch (field.type) {
      case "email":
      case "text":
        return <TextField {..._field} />;
      case "select":
        return <SelectField {..._field} />;
      case "radio":
        return <RadioField {..._field} />;
      case "textarea":
        return <TextAreaField {..._field} />;
      case "checkbox":
        return <CheckboxField {..._field} />;
      case "group":
        return this.getGroupField(_field);
      case "submit":
        return this.props.renderSubmit(this.onSubmit, this.state);
      case "form":
        return this.props.renderForm();
      case "ring":
        return <RingImage />;
      case "store":
        return (
          <div className={field.className}>
            <StoreLocationContainer field={field} fieldError={field.error} />
          </div>
        );

      case "payment":
        return <PaymentContainer {..._field} errors={errors} />;
      case "shipping":
        return (
          <ShippingContainer onSelect={this.props.onSelect} errors={errors} />
        );
      case "googlePlaces":
        return (
          <LocationSearchInput
            field={field}
            onSelect={this.props.onSelect}
            fieldError={field.error}
          />
        );
      case "upload":
        return <UploadField {..._field} fieldError={field.error} />;
      case "date":
        return <DateField {..._field} fieldError={field.error} />;
      case "file":
        return (
          <FileField
            {..._field}
            fieldError={field.error}
            onChange={e => {
              this.setState({ file: e.target.files[0] });
            }}
          />
        );
      default:
        return <TextField {..._field} />;
    }
  }

  createField(field) {
    return this.getField(field);
  }

  validate = values => {
    const errors = {};

    const {
      form: { fields }
    } = this.props;

    fields.forEach(field => {
      if (field.required && !values[field.id]) {
        errors[field.id] = "Required Field";
      } else if (
        field.validate === "phone" &&
        !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field.id])
      ) {
        errors[field.id] = "Invalid phone number";
      } else if (
        field.type === "email" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.id])
      ) {
        errors[field.id] = "Invalid email address";
      }

      if (field.type === "group") {
        field.fields.forEach(field => {
          if (field.required && !values[field.id]) {
            errors[field.id] = "Required Field";
          } else if (
            field.validate === "phone" &&
            !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field.id])
          ) {
            errors[field.id] = "Invalid phone number";
          } else if (
            field.type === "email" &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field.id])
          ) {
            errors[field.id] = "Invalid email address";
          } else if (field.type === "shipping") {
            if (!values.delivery) {
              errors[`delivery`] = "Required Field";
            } else {
              console.log(values, "shippingDetails");
              const required =
                values.delivery === "collection"
                  ? ["store"]
                  : [
                      "firstName",
                      "lastName",
                      "phoneNumber",
                      "addressLine1",
                      "suburb",
                      "city",
                      "province",
                      "postalCode"
                    ];

              required.forEach(r => {
                if (!values[`shipping.${r}`]) {
                  errors[`shipping.${r}`] = "Required Field";
                }
              });
            }
          }
        });
      }
    });

    return errors;
  };

  onSubmit = e => {
    const { formId } = this.props;
    e.preventDefault();

    this.setState({
      errors: {},
      error: null,
      sent: false,
      loading: true
    });

    const values = serializeForm(e.target, { hash: true });

    const errors = this.validate(values);

    const formValues = Object.keys(values).reduce((result, item) => {
      const [tag, param] = item.split(".");

      if (param) {
        if (result[tag]) {
          result[tag][param] = values[item];
        } else {
          result[tag] = { [param]: values[item] };
        }
      } else {
        result[tag] = values[item];
      }

      return result;
    }, {});

    if (formId === "order" && !values.agreement) {
      errors["agreement"] = "Required Field";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
        sent: false,
        loading: false
      });

      return false;
    }

    if (this.props.submitProcess) {
      console.log(errors, formValues);
      this.props.submitProcess(errors, formValues, this.state.file);
    }
  };

  render() {
    const { loading, form, error, as: FormWrapper = "form" } = this.props;

    if (loading) return null;
    if (error) return <h1>ERROR</h1>;

    return (
      <FormWrapper onSubmit={this.onSubmit} className="row" noValidate>
        {form.fields.map(f => this.createField(f))}
      </FormWrapper>
    );
  }
}

FormContainer.defaultProps = {
  formId: null,
  onSelect: () => null
};

const FORM_QUERY = gql`
  fragment FieldParts on Fields {
    label
    box
    placeholder
    description
    id
    type
    className
    required
    options {
      label
      value
    }
    conditionalLogic {
      actionType
      logic {
        field
        match
        value
      }
    }
  }
  query getForm($formId: String!) {
    form(id: $formId) {
      id
      title
      description
      submission
      buttonLabel
      fields {
        ...FieldParts
        fields {
          ...FieldParts
        }
      }
    }
  }
`;

const withForm = graphql(FORM_QUERY, {
  options: props => {
    return {
      variables: {
        formId: props.formId
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withForm(FormContainer);
