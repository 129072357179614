import React, { Component } from "react";
import {
  PBody,
  PHeader,
  PDescription,
  PModule,
  Copy
} from "@lucio-erasmus/sterns-components";
import ProductSelector from "./ProductSelector";

class Selection extends Component {
  state = { active: null, showOptions: true, activeIndex: null };

  findProduct = item => {
    const { product, errors = {} } = this.props;

    return (
      <React.Fragment>
        <ProductSelector item={item} product={product} />
        {errors[item.sku] ? (
          <div className="w-100">
            <Copy size="tiny" mb="none" color={"error"}>
              {errors[item.sku]}
            </Copy>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  mouseEnter = index => {
    this.setState({
      activeIndex: index
    });
  };

  mouseLeave = () => {
    this.setState({
      activeIndex: null
    });
  };

  showOptions() {
    const {
      name,
      description,
      multiple = false,
      options = [],
      onClose,
      subtitle,
      product,
      errors = {}
    } = this.props;

    const excludedOptions = options.filter(o => {
      let excluded = true;

      if (o.exclusions && o.exclusions.length) {
        excluded = o.exclusions.some(e => {
          const match = product.some(p =>
            p.sku === e.key ? e.values.includes(p.id) : false
          );

          return !match;
        });
      }

      return excluded;
    });

    const filteredOptions = excludedOptions.map(o => {
      const index = product.map(p => p.sku).indexOf(o.sku);
      return {
        ...o,
        inProductArray: index > -1
      };
    });

    const hasItemInArray = filteredOptions.filter(o => o.inProductArray).length;

    console.log(errors);

    return (
      <PModule title={name} subtitle={subtitle} parent onClose={onClose}>
        {description && (
          <PBody hasPadding={true}>
            <PDescription html={description} />
          </PBody>
        )}

        {excludedOptions.map((o, index) => {
          let headingProps = {};
          const e = product.find(p => p.sku === o.sku);

          if (e) {
            headingProps.icon = "done";
          }

          if (index === this.state.activeIndex) {
            headingProps = {
              hover: true,
              icon: e ? "edit" : "angle-right"
            };
          }

          if (hasItemInArray) {
            const show =
              product.map(p => p.sku).indexOf(o.sku) > -1 || multiple;

            if (!show) {
              headingProps.muted = true;
              headingProps.hover = false;
            }

            return (
              <PHeader
                title={o.name}
                onClick={
                  show
                    ? () => this.props.onSelect({ ...o, parent: this.props })
                    : null
                }
                onEnter={() => this.mouseEnter(index)}
                onLeave={() => this.mouseLeave()}
                {...headingProps}
              >
                <div>
                  {show ? (
                    this.findProduct(o)
                  ) : (
                    <PDescription>
                      This option is not available with your current selection.
                      Please change some of your options to try a different
                      combination.
                    </PDescription>
                  )}
                </div>
              </PHeader>
            );
          }

          return (
            <PHeader
              title={o.name}
              icon="angle-right"
              onClick={() => this.props.onSelect({ ...o, parent: this.props })}
              onEnter={() => this.mouseEnter(index)}
              onLeave={() => this.mouseLeave()}
              {...headingProps}
            >
              {this.findProduct(o)}
            </PHeader>
          );
        })}
      </PModule>
    );
  }

  render() {
    return this.showOptions();
  }
}

export default Selection;
