import React from "react";

export const HomeImageContext = React.createContext();

export default function HomeImageContextProvider({ children }) {
  const [imageData, setImageData] = React.useState(null);
  function setImage(image) {
    setImageData(image);
  }
  return (
    <HomeImageContext.Provider value={{ imageData, setImage }}>
      {children}
    </HomeImageContext.Provider>
  );
}
