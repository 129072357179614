import React, { Component, Fragment } from 'react';
import { NavItem, Icon } from '@lucio-erasmus/sterns-components';
import { ProductContext } from '../Store/CartContext';

class ProductSelection extends Component {
  removeItem = (sku, parent, connected) => {
    this.context.onProductDelete({
      sku,
      parent,
      connected,
    });
  };

  render() {
    const { group, name, sku, parentSku, connected, errors } = this.props;

    return (
      <NavItem textAlign="left" display="block" mb="8px" mt="8px">
        <div style={{ position: 'absolute' }}>
          <Icon
            onClick={() => this.removeItem(sku, parentSku, connected)}
            name="close"
            ml="none"
          />
        </div>
        <div style={{ marginLeft: '1rem' }}>
          {this.props.children ? (
            this.props.children
          ) : (
            <Fragment>
              {group}: {name}
            </Fragment>
          )}
        </div>
        {errors ? errors : null}
      </NavItem>
    );
  }
}

ProductSelection.contextType = ProductContext;

export default ProductSelection;
