var app = {};

var _pGlob = {};
var ring_loaded = {};
var wb_loaded = {};
var engraving_ao;
var engraving_normal;
var engraving_ao_wb;
var engraving_normal_wb;

_pGlob.objCache = {};
var CONTAINER_ID = "verge3d-container";

const METAL_MAP = {
  "9ctyg": "yellow_gold_18ct",
  "18ctyg": "yellow_gold_18ct",
  silver: "silver",
  platinum: "silver",
  pt: "silver",
  "9CTWG": "silver",
  "18ctwg": "silver",
  "18CTWG": "silver",
  "18ctrg": "rose_gold_18ct",
  "9ctrg": "rose_gold_18ct"
};

const metall_colors = {
  yellow_gold_18ct: [0, 1, 0.68, 0.347, 0.789, 0.56, 0.305],
  silver: [0, 1, 1, 1, 1, 1, 1],
  rose_gold_18ct: [0, 1, 0.675, 0.486, 1, 0.473, 0.224]
};

var NORMALGEN = {};

NORMALGEN.normalCanvas = null;
NORMALGEN.renderer = null;

NORMALGEN.convert = function(heightImage, params) {
  // init renderer
  var normalCanvas = (NORMALGEN.normalCanvas =
    NORMALGEN.normalCanvas || document.createElement("canvas"));
  var renderer = (NORMALGEN.renderer =
    NORMALGEN.renderer ||
    new window.v3d.WebGLRenderer({
      alpha: false,
      antialias: true,
      canvas: normalCanvas
    }));
  renderer.setSize(params.width, params.height);

  // init normal map shader
  var normalShader = NORMALGEN.NormalMapShader;
  var normalUniforms = window.v3d.UniformsUtils.clone(normalShader.uniforms);

  // set width and height
  normalUniforms["dimensions"].value = [params.width, params.height, 0];

  // set strength and level
  normalUniforms["dz"].value =
    (1.0 / params.strength) * (1.0 + Math.pow(2.0, params.level));

  // set filter
  normalUniforms["type"].value = params.filter === "Sobel" ? 0 : 1;

  // set inversion flags
  normalUniforms["invertR"].value = params.invertR ? -1 : 1;
  normalUniforms["invertG"].value = params.invertG ? -1 : 1;
  normalUniforms["invertH"].value = params.invertHeight ? -1 : 1;

  // setup texture
  var heightTex = new window.v3d.Texture(heightImage);
  heightTex.minFilter = heightTex.magFilter = window.v3d.NearestFilter;
  heightTex.needsUpdate = true;
  normalUniforms["tHeightMap"].value = heightTex;

  // setup material
  var normalMat = new window.v3d.ShaderMaterial({
    fragmentShader: normalShader.fragmentShader,
    vertexShader: normalShader.vertexShader,
    uniforms: normalUniforms
  });

  // setup mesh
  var geometry = new window.v3d.PlaneBufferGeometry(1, 1, 1, 1);
  var mesh = new window.v3d.Mesh(geometry, normalMat);
  mesh.name = "NORMALGEN_MESH";

  // setup scene
  var scene = new window.v3d.Scene();
  scene.add(mesh);
  var camera = new window.v3d.OrthographicCamera(
    1 / -2,
    1 / 2,
    1 / 2,
    1 / -2,
    0,
    1
  );
  var normalPass = new window.v3d.RenderPass(scene, camera);

  // blur passes
  var gaussianPassY = new window.v3d.ShaderPass(NORMALGEN.VerticalBlurShader);
  var gaussianPassX = new window.v3d.ShaderPass(NORMALGEN.HorizontalBlurShader);

  // set blur params
  gaussianPassY.uniforms["v"].value = params.blur / params.width / 5;
  gaussianPassX.uniforms["h"].value = params.blur / params.height / 5;

  // setup render target
  var rtParams = {
    minFilter: window.v3d.NearestFilter,
    magFilter: window.v3d.NearestFilter,
    format: window.v3d.RGBAFormat,
    stencilBufer: false
  };
  var renderTarget = new window.v3d.WebGLRenderTarget(
    params.width,
    params.height,
    rtParams
  );

  // setup composer
  var composer = new window.v3d.EffectComposer(renderer, renderTarget);
  composer.setSize(params.width, params.height);
  composer.addPass(normalPass);
  composer.addPass(gaussianPassY);
  composer.addPass(gaussianPassX);

  // draw
  composer.render(1 / 60);

  // retrieve image
  var resultImage = normalCanvas.toDataURL();

  // clean up
  normalMat.dispose();
  scene.dispose();
  composer.dispose();
  renderer.dispose();

  return resultImage;
};

NORMALGEN.NormalMapShader = {
  uniforms: {
    type: { type: "1i", value: 0 },
    invertR: { type: "1f", value: 1 },
    invertG: { type: "1f", value: 1 },
    invertH: { type: "1f", value: 1 },
    dz: { type: "1f", value: 0 },
    dimensions: { type: "fv", value: [0, 0, 0] },
    tHeightMap: { type: "t", value: null }
  },

  vertexShader: [
    "precision mediump float;",
    "varying vec2 vUv;",
    "varying vec2 step;",
    "uniform vec3 dimensions;",
    "void main() {",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
    "step = vec2(-1.0 / dimensions.x, -1.0 / dimensions.y);", // - to switch from glsl orientation to my orientation :D
    "vUv = uv;",
    "}"
  ].join("\n"),

  fragmentShader: [
    "precision mediump float;",
    "uniform vec3 dimensions;",
    "varying vec2 vUv;",
    "varying vec2 step;",
    "uniform float dz;",
    "uniform float invertR;",
    "uniform float invertG;",
    "uniform float invertH;",
    "uniform int type;",
    "uniform sampler2D tHeightMap;",

    "void main(void) {",
    "	vec2 tlv = vec2(vUv.x - step.x, vUv.y + step.y );",
    "	vec2 lv  = vec2(vUv.x - step.x, vUv.y 		   );",
    "	vec2 blv = vec2(vUv.x - step.x, vUv.y - step.y);",
    "	vec2 tv  = vec2(vUv.x 		  , vUv.y + step.y );",
    "	vec2 bv  = vec2(vUv.x 		  , vUv.y - step.y);",
    "	vec2 trv = vec2(vUv.x + step.x, vUv.y + step.y );",
    "	vec2 rv  = vec2(vUv.x + step.x, vUv.y 		   );",
    "	vec2 brv = vec2(vUv.x + step.x, vUv.y - step.y);",
    "	tlv = vec2(tlv.x >= 0.0 ? tlv.x : (1.0 + tlv.x), 	tlv.y >= 0.0	? tlv.y : (1.0  + tlv.y));",
    "	tlv = vec2(tlv.x < 1.0  ? tlv.x : (tlv.x - 1.0 ), 	tlv.y < 1.0   	? tlv.y : (tlv.y - 1.0 ));",
    "	lv  = vec2( lv.x >= 0.0 ?  lv.x : (1.0 + lv.x),  	lv.y  >= 0.0 	?  lv.y : (1.0  +  lv.y));",
    "	lv  = vec2( lv.x < 1.0  ?  lv.x : ( lv.x - 1.0 ),   lv.y  < 1.0  	?  lv.y : ( lv.y - 1.0 ));",
    "	blv = vec2(blv.x >= 0.0 ? blv.x : (1.0 + blv.x), 	blv.y >= 0.0 	? blv.y : (1.0  + blv.y));",
    "	blv = vec2(blv.x < 1.0  ? blv.x : (blv.x - 1.0 ), 	blv.y < 1.0 	? blv.y : (blv.y - 1.0 ));",
    "	tv  = vec2( tv.x >= 0.0 ?  tv.x : (1.0 + tv.x),  	tv.y  >= 0.0 	?  tv.y : (1.0  +  tv.y));",
    "	tv  = vec2( tv.x < 1.0  ?  tv.x : ( tv.x - 1.0 ),   tv.y  < 1.0 	?  tv.y : ( tv.y - 1.0 ));",
    "	bv  = vec2( bv.x >= 0.0 ?  bv.x : (1.0 + bv.x),  	bv.y  >= 0.0 	?  bv.y : (1.0  +  bv.y));",
    "	bv  = vec2( bv.x < 1.0  ?  bv.x : ( bv.x - 1.0 ),   bv.y  < 1.0 	?  bv.y : ( bv.y - 1.0 ));",
    "	trv = vec2(trv.x >= 0.0 ? trv.x : (1.0 + trv.x), 	trv.y >= 0.0 	? trv.y : (1.0  + trv.y));",
    "	trv = vec2(trv.x < 1.0  ? trv.x : (trv.x - 1.0 ), 	trv.y < 1.0   	? trv.y : (trv.y - 1.0 ));",
    "	rv  = vec2( rv.x >= 0.0 ?  rv.x : (1.0 + rv.x),  	rv.y  >= 0.0 	?  rv.y : (1.0  +  rv.y));",
    "	rv  = vec2( rv.x < 1.0  ?  rv.x : ( rv.x - 1.0 ),   rv.y  < 1.0   	?  rv.y : ( rv.y - 1.0 ));",
    "	brv = vec2(brv.x >= 0.0 ? brv.x : (1.0 + brv.x), 	brv.y >= 0.0 	? brv.y : (1.0  + brv.y));",
    "	brv = vec2(brv.x < 1.0  ? brv.x : (brv.x - 1.0 ), 	brv.y < 1.0   	? brv.y : (brv.y - 1.0 ));",
    "	float tl = abs(texture2D(tHeightMap, tlv).r);",
    "	float l  = abs(texture2D(tHeightMap, lv ).r);",
    "	float bl = abs(texture2D(tHeightMap, blv).r);",
    "	float t  = abs(texture2D(tHeightMap, tv ).r);",
    "	float b  = abs(texture2D(tHeightMap, bv ).r);",
    "	float tr = abs(texture2D(tHeightMap, trv).r);",
    "	float r  = abs(texture2D(tHeightMap, rv ).r);",
    "	float br = abs(texture2D(tHeightMap, brv).r);",
    "   float dx = 0.0, dy = 0.0;",
    "   if(type == 0){", // Sobel
    "		dx = tl + l*2.0 + bl - tr - r*2.0 - br;",
    "		dy = tl + t*2.0 + tr - bl - b*2.0 - br;",
    "   }",
    "   else{", // Scharr
    "		dx = tl*3.0 + l*10.0 + bl*3.0 - tr*3.0 - r*10.0 - br*3.0;",
    "		dy = tl*3.0 + t*10.0 + tr*3.0 - bl*3.0 - b*10.0 - br*3.0;",
    "   }",
    "	vec4 normal = vec4(normalize(vec3(dx * invertR * invertH * 255.0, dy * invertG * invertH * 255.0, dz)), texture2D(tHeightMap, vUv).a);",
    "	gl_FragColor = vec4(normal.xy * 0.5 + 0.5, normal.zw);",
    "}"
  ].join("\n")
};

NORMALGEN.HorizontalBlurShader = {
  uniforms: {
    tDiffuse: { type: "t", value: null },
    h: { type: "f", value: 3.0 / 512.0 }
  },

  vertexShader: [
    "varying vec2 vUv;",

    "void main() {",

    "vUv = uv;",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

    "}"
  ].join("\n"),

  fragmentShader: [
    "uniform sampler2D tDiffuse;",
    "uniform float h;",

    "varying vec2 vUv;",

    "void main() {",

    "vec4 sum = vec4( 0.0 );",
    "float lef4 = vUv.x - 4.0 * h;",
    "float lef3 = vUv.x - 3.0 * h;",
    "float lef2 = vUv.x - 2.0 * h;",
    "float lef1 = vUv.x - 1.0 * h;",
    "float rig1 = vUv.x + 1.0 * h;",
    "float rig2 = vUv.x + 2.0 * h;",
    "float rig3 = vUv.x + 3.0 * h;",
    "float rig4 = vUv.x + 4.0 * h;",

    "lef4 = lef4 >= 0.0 ? lef4 : (1.0 + lef4);",
    "lef4 = lef4 < 1.0  ? lef4 : (lef4 - 1.0 );",
    "lef3 = lef3 >= 0.0 ? lef3 : (1.0 + lef3);",
    "lef3 = lef3 < 1.0  ? lef3 : (lef3 - 1.0 );",
    "lef2 = lef2 >= 0.0 ? lef2 : (1.0 + lef2);",
    "lef2 = lef2 < 1.0  ? lef2 : (lef2 - 1.0 );",
    "lef1 = lef1 >= 0.0 ? lef1 : (1.0 + lef1);",
    "lef1 = lef1 < 1.0  ? lef1 : (lef1 - 1.0 );",
    "rig1 = rig1 >= 0.0 ? rig1 : (1.0 + rig1);",
    "rig1 = rig1 < 1.0  ? rig1 : (rig1 - 1.0 );",
    "rig2 = rig2 >= 0.0 ? rig2 : (1.0 + rig2);",
    "rig2 = rig2 < 1.0  ? rig2 : (rig2 - 1.0 );",
    "rig3 = rig3 >= 0.0 ? rig3 : (1.0 + rig3);",
    "rig3 = rig3 < 1.0  ? rig3 : (rig3 - 1.0 );",
    "rig4 = rig4 >= 0.0 ? rig4 : (1.0 + rig4);",
    "rig4 = rig4 < 1.0  ? rig4 : (rig4 - 1.0 );",

    "sum += texture2D( tDiffuse, vec2( lef4, vUv.y ) ) * 0.051;",
    "sum += texture2D( tDiffuse, vec2( lef3, vUv.y ) ) * 0.0918;",
    "sum += texture2D( tDiffuse, vec2( lef2, vUv.y ) ) * 0.12245;",
    "sum += texture2D( tDiffuse, vec2( lef1, vUv.y ) ) * 0.1531;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, vUv.y ) ) * 0.1633;",
    "sum += texture2D( tDiffuse, vec2( rig1, vUv.y ) ) * 0.1531;",
    "sum += texture2D( tDiffuse, vec2( rig2, vUv.y ) ) * 0.12245;",
    "sum += texture2D( tDiffuse, vec2( rig3, vUv.y ) ) * 0.0918;",
    "sum += texture2D( tDiffuse, vec2( rig4, vUv.y ) ) * 0.051;",
    "if (h > 0.0){",
    "	vec4 srcValue = texture2D( tDiffuse, vec2( vUv.x, vUv.y ) );",
    "	sum = srcValue + srcValue - sum;",
    "}",
    "gl_FragColor = sum;",
    //'gl_FragColor = vec4(1,0,0,1);',

    "}"
  ].join("\n")
};

NORMALGEN.VerticalBlurShader = {
  uniforms: {
    tDiffuse: { type: "t", value: null },
    v: { type: "f", value: 3.0 / 512.0 }
  },

  vertexShader: [
    "varying vec2 vUv;",

    "void main() {",

    "vUv = uv;",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

    "}"
  ].join("\n"),

  fragmentShader: [
    "uniform sampler2D tDiffuse;",
    "uniform float v;",

    "varying vec2 vUv;",

    "void main() {",

    "vec4 sum = vec4( 0.0 );",
    "float top4 = vUv.y - 4.0 * v;",
    "float top3 = vUv.y - 3.0 * v;",
    "float top2 = vUv.y - 2.0 * v;",
    "float top1 = vUv.y - 1.0 * v;",
    "float bot1 = vUv.y + 1.0 * v;",
    "float bot2 = vUv.y + 2.0 * v;",
    "float bot3 = vUv.y + 3.0 * v;",
    "float bot4 = vUv.y + 4.0 * v;",

    "top4 = top4 >= 0.0 ? top4 : (1.0 + top4);",
    "top4 = top4 < 1.0  ? top4 : (top4 - 1.0 );",
    "top3 = top3 >= 0.0 ? top3 : (1.0 + top3);",
    "top3 = top3 < 1.0  ? top3 : (top3 - 1.0 );",
    "top2 = top2 >= 0.0 ? top2 : (1.0 + top2);",
    "top2 = top2 < 1.0  ? top2 : (top2 - 1.0 );",
    "top1 = top1 >= 0.0 ? top1 : (1.0 + top1);",
    "top1 = top1 < 1.0  ? top1 : (top1 - 1.0 );",
    "bot1 = bot1 >= 0.0 ? bot1 : (1.0 + bot1);",
    "bot1 = bot1 < 1.0  ? bot1 : (bot1 - 1.0 );",
    "bot2 = bot2 >= 0.0 ? bot2 : (1.0 + bot2);",
    "bot2 = bot2 < 1.0  ? bot2 : (bot2 - 1.0 );",
    "bot3 = bot3 >= 0.0 ? bot3 : (1.0 + bot3);",
    "bot3 = bot3 < 1.0  ? bot3 : (bot3 - 1.0 );",
    "bot4 = bot4 >= 0.0 ? bot4 : (1.0 + bot4);",
    "bot4 = bot4 < 1.0  ? bot4 : (bot4 - 1.0 );",

    "sum += texture2D( tDiffuse, vec2( vUv.x, top4 ) ) * 0.051;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, top3 ) ) * 0.0918;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, top2 ) ) * 0.12245;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, top1 ) ) * 0.1531;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, vUv.y ) ) * 0.1633;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, bot1 ) ) * 0.1531;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, bot2 ) ) * 0.12245;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, bot3 ) ) * 0.0918;",
    "sum += texture2D( tDiffuse, vec2( vUv.x, bot4 ) ) * 0.051;",
    "if (v > 0.0){",
    "	vec4 srcValue = texture2D( tDiffuse, vec2( vUv.x, vUv.y ) );",
    "	sum = srcValue + srcValue - sum;",
    "}",
    "gl_FragColor = sum;",

    "}"
  ].join("\n")
};

// generateNormalMap puzzle
function generateNormalMap(
  srcURL,
  strength,
  level,
  blur,
  filter,
  invertR,
  invertG,
  invertHeight
) {
  return new Promise(function(resolve, reject) {
    var heightImage = new Image();
    heightImage.src = srcURL;
    heightImage.onload = function() {
      var output = NORMALGEN.convert(heightImage, {
        width: heightImage.width,
        height: heightImage.height,
        strength: strength,
        level: level,
        blur: blur,
        filter: filter,
        invertR: invertR,
        invertG: invertG,
        invertHeight: invertHeight
      });
      resolve(output);
    };

    heightImage.onerror = function() {
      var pink =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAAC" +
        "QkWg2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wgNDjoFwUNXbAAAABpJREF" +
        "UKM9jfF52jYEUwMRAIhjVMKph6GgAAOxFAlM1pWHTAAAAAElFTkSuQmCC";

      resolve(pink); // return a pink image instead of failing
    };
  });
}

export const initVerge3D = (sceneURL, options = {}, cb) => {
  var params = window.v3d.AppUtils.getPageParams();

  if (!sceneURL) {
    console.log(params);
    console.log("No scene URL specified");
    return;
  }

  // some puzzles can benefit from cache
  window.v3d.Cache.enabled = true;

  return loadSceneInit(sceneURL, options, cb);
};

function unloadScene(url) {
  if (app.scene) {
    var scene = url === "" ? app.scene : app.scene.getObjectByName(url);
    if (scene) app.unload(scene);

    if (!app.scene) app.renderer.clear();

    // clean object cache
    _pGlob.objCache = {};
  }
}

function appendScene(url, loadCameras, loadLights, loadCb, progCb) {
  _pGlob.percentage = 0;
  app.appendScene(
    url,
    function(loadedScene) {
      loadedScene.name = url;
      _pGlob.percentage = 100;
      loadCb();
    },
    function(percentage) {
      _pGlob.percentage = percentage;
      progCb();
    },
    null,
    loadCameras,
    loadLights
  );
}

const loadSceneInit = (sceneURL, initOptions, cb) => {
  initOptions = initOptions || {};

  var ctxSettings = {};
  if (initOptions.useBkgTransp) ctxSettings.alpha = true;
  if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

  var preloader = initOptions.useCustomPreloader
    ? createCustomPreloader(
        initOptions.preloaderProgressCb,
        initOptions.preloaderEndCb
      )
    : new window.v3d.SimplePreloader({
        container: "verge3d-container",
        imageURL: `${process.env.PUBLIC_URL}/loader.gif`,
        imageRotationSpeed: 0,
        width: "100px"
      });

  if (window.v3d.PE) {
    //    puzzlesEditorPreparePreloader(preloader);
  }

  app = new window.v3d.App(CONTAINER_ID, ctxSettings, preloader);
  if (initOptions.useBkgTransp) {
    app.clearBkgOnLoad = true;
    app.renderer.setClearColor(0x000000, 0);
  }

  // namespace for communicating with code generated by Puzzles
  app.ExternalInterface = {};
  prepareExternalInterface(app);

  if (initOptions.preloaderStartCb) initOptions.preloaderStartCb();
  if (initOptions.useFullscreen) {
    //initFullScreen();
  } else {
    var fsButton = document.getElementById("fullscreen_button");
    if (fsButton) fsButton.style.display = "none";
  }

  sceneURL = initOptions.useCompAssets ? sceneURL + ".xz" : sceneURL;
  app.loadScene(
    sceneURL,
    function() {
      app.enableControls();
      app.run();

      if (window.v3d.PE) window.v3d.PE.updateAppInstance(app);
      if (window.v3d.PL) window.v3d.PL.init(app, initOptions);
      cb();
      runCode(app);
    },
    null,
    function() {
      console.log("Can't load the scene " + sceneURL);
    }
  );

  return app;
};

function loadNewScene(url, loadCb, progCb) {
  app.unload();

  _pGlob.objCache = {};

  _pGlob.percentage = 0;
  app.loadScene(
    url,
    function(loadedScene) {
      app.enableControls();
      loadedScene.name = url;

      //refraction("diamond.em", 10, '');
      //refraction('Diamond', 3, '');
      _pGlob.percentage = 100;
      loadCb();
    },
    function(percentage) {
      _pGlob.percentage = percentage;
      progCb();
    },
    null
  );
}

export function loadScene(sceneURL, initOptions, cb) {
  return new Promise((resolve, reject) => {
    initOptions = initOptions || {};
    _pGlob.objCache = {};

    var ctxSettings = {};
    if (initOptions.useBkgTransp) ctxSettings.alpha = true;
    if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

    var preloader = initOptions.useCustomPreloader
      ? createCustomPreloader(
          initOptions.preloaderProgressCb,
          initOptions.preloaderEndCb
        )
      : new window.v3d.SimplePreloader({
          container: "verge3d-container",
          imageURL: `${process.env.PUBLIC_URL}/loader.gif`,
          imageRotationSpeed: 0,
          width: "100px"
        });

    app = new window.v3d.App("verge3d-container", ctxSettings, preloader);
    if (initOptions.useBkgTransp) {
      app.clearBkgOnLoad = true;
      app.renderer.setClearColor(0xffffff, 0);
    }
    prepareExternalInterface(app);
    if (initOptions.preloaderStartCb) initOptions.preloaderStartCb();
    if (initOptions.useFullscreen) {
      //initFullScreen();
    } else {
      //var fsButton = document.getElementById("fullscreen_button");
      //if (fsButton) fsButton.style.display = "none";
    }

    let ext = initOptions.useCompAssets ? ".gltf.xz" : ".gltf";
    app.load(
      `${process.env.REACT_APP_RINGS_URL ||
        process.env.PUBLIC_URL}/rings4/${sceneURL}/Rings_${sceneURL}${ext}`,
      function() {
        app.enableControls();
        app.run();

        if (window.v3d.PE) window.v3d.PE.updateapp(app);
        if (window.v3d.PL) window.v3d.PL.init(app, initOptions);

        runCode(app);

        cb();

        return resolve();
      },
      function() {
        return reject("Can't load the scene " + sceneURL);
      },
      false
    );
  });
}

function createCustomPreloader(updateCb, finishCb) {
  function CustomPreloader() {
    window.v3d.Preloader.call(this);
  }

  CustomPreloader.prototype = Object.assign(
    Object.create(window.v3d.Preloader.prototype),
    {
      onUpdate: function(percentage) {
        window.v3d.Preloader.prototype.onUpdate.call(this, percentage);
        if (updateCb) updateCb(percentage);
      },
      onFinish: function() {
        window.v3d.Preloader.prototype.onFinish.call(this);
        if (finishCb) finishCb();
      }
    }
  );

  return new CustomPreloader();
}

/*function initFullScreen() {
  var fsButton = document.getElementById("fullscreen_button");
  if (!fsButton) return;

  if (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  )
    window.fullscreen_button.style.display = "inline";

  window.fullscreen_button.addEventListener("click", function(event) {
    event.stopPropagation();
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      exitFullscreen();
    } else requestFullscreen(document.body);
  });

  function changeFullscreen() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    )
      window.fullscreen_button.className = "fullscreen-close";
    else window.fullscreen_button.className = "fullscreen-open";
  }

  document.addEventListener("webkitfullscreenchange", changeFullscreen);
  document.addEventListener("mozfullscreenchange", changeFullscreen);
  document.addEventListener("msfullscreenchange", changeFullscreen);
  document.addEventListener("fullscreenchange", changeFullscreen);

  function requestFullscreen(elem) {
    if (elem.requestFullscreen) elem.requestFullscreen();
    else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
  }

  function exitFullscreen() {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
  }
}
*/
function prepareExternalInterface(app) {}

function runCode(app) {}

export const setMaterial = (material, objects) => {
  if (!app.scene) return null;

  assignMat(objects, material);
  //console.log(app.renderer.domElement.toDataURL("image/png"));
};

export const setColor = (color, objects, mat) => {
  if (!app.scene) return null;
  assignColor(objects, mat, color);
};

export const setDiamond = (objects, config) => {
  if (!app.scene) return null;
  assignDiamond(objects, config.shape);
};

function assignDiamond(arr, shape) {
  let objNames = retrieveObjectNames(arr);

  if (!objNames) return;

  var mat1 = window.v3d.SceneUtils.getMaterialByName(app, "diamond.latest");
  var mat2 = window.v3d.SceneUtils.getMaterialByName(app, `base.${shape}`);

  if (!mat1) return;
  for (var i = 0; i < objNames.length; i++) {
    var objName = objNames[i];

    if (!objName) continue;
    var obj = getObjectByName(objName);

    if (obj) {
      //obj.material = mat;
      if (obj.children.length) {
        obj.children[0].material = mat1;
        obj.children[1].material = mat2;
        obj.children[0].material.needsUpdate = true;
        obj.children[1].material.needsUpdate = true;
      }
    }
  }
}

function assignMat(arr, matName) {
  let objNames = retrieveObjectNames(arr);

  if (!arr || !matName) return;

  var mat = window.v3d.SceneUtils.getMaterialByName(app, matName);

  if (!mat) return;
  for (var i = 0; i < objNames.length; i++) {
    var objName = objNames[i];

    if (!objName) continue;
    var obj = getObjectByName(objName);

    if (obj) {
      obj.material = mat;
    }
  }
}

function assignColor(arr, matName, quality) {
  let objNames = retrieveObjectNames(arr);
  if (!objNames || !matName) return;

  var mat = window.v3d.SceneUtils.getMaterialByName(app, matName);

  if (!mat) return;

  for (var i = 0; i < objNames.length; i++) {
    var objName = objNames[i];
    if (!objName) continue;
    var obj = getObjectByName(objName);

    if (obj.children && obj.children.length) {
      const diamondIndex = 0;
      if (diamondIndex > -1) {
        const materialColor = materials.find(m => m.name === quality);

        obj.children[diamondIndex].material.nodeRGB[0].x = materialColor.r;
        obj.children[diamondIndex].material.nodeRGB[0].y = materialColor.g;
        obj.children[diamondIndex].material.nodeRGB[0].z = materialColor.b;

        obj.children[diamondIndex].material.needsUpdate = true;
      }
    } else {
      const materialColor = materials.find(m => m.name === quality);
      obj.material.nodeRGB[0].x = materialColor.r;
      obj.material.nodeRGB[0].y = materialColor.g;
      obj.material.nodeRGB[0].z = materialColor.b;

      obj.material.needsUpdate = true;
    }
  }
}

function getObjectByName(objName) {
  var objFound;
  var runTime = typeof _pGlob !== "undefined";
  objFound = runTime ? _pGlob.objCache[objName] : null;
  if (objFound && objFound.name === objName) return objFound;
  app.scene.traverse(function(obj) {
    if (!objFound && notIgnoredObj(obj) && obj.name === objName) {
      objFound = obj;
      if (runTime) _pGlob.objCache[objName] = objFound;
    }
  });
  return objFound;
}

function notIgnoredObj(obj) {
  return (
    obj.type !== "Scene" &&
    obj.type !== "AmbientLight" &&
    obj.name !== "" &&
    !(obj.isMesh && obj.isMaterialGeneratedMesh)
  );
}

function retrieveObjectNames(objNames) {
  var acc = [];
  retrieveObjectNamesAcc(objNames, acc);
  return acc;
}

function retrieveObjectNamesAcc(currObjNames, acc) {
  if (typeof currObjNames === "string") {
    acc.push(currObjNames);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === "GROUP") {
    let newObj = getObjectNamesByGroupName(currObjNames[1]);
    for (let i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === "ALL_OBJECTS") {
    let newObj = getAllObjectNames();
    for (let i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames)) {
    for (let i = 0; i < currObjNames.length; i++)
      retrieveObjectNamesAcc(currObjNames[i], acc);
  }
}

function getObjectNamesByGroupName(targetGroupName) {
  var objNameList = [];
  app.scene.traverse(function(obj) {
    if (notIgnoredObj(obj)) {
      var groupNames = obj.groupNames;
      if (!groupNames) return;
      for (var i = 0; i < groupNames.length; i++) {
        var groupName = groupNames[i];
        if (groupName === targetGroupName) {
          objNameList.push(obj.name);
        }
      }
    }
  });
  return objNameList;
}

function getAllObjectNames() {
  var objNameList = [];
  app.scene.traverse(function(obj) {
    if (notIgnoredObj(obj)) objNameList.push(obj.name);
  });
  return objNameList;
}

export const loadRing = (sceneUrl, cb) => {
  loadNewScene(
    sceneUrl,
    function(a) {
      cb();
    },
    function(err) {}
  );
};

/*function bloom(threshold, strength, radius) {
  app.enablePostprocessing([
    {
      type: "bloom",
      threshold: threshold,
      strength: strength,
      radius: radius
    }
  ]);
}*/

function textureFromText(text, width, height, family, size, align, offset) {
  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext("2d");
  // clear color first to prevent severe pixelating
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.font = size + "px " + family;
  ctx.fillStyle = "black";

  var x;
  switch (align) {
    case "CENTER":
      ctx.textAlign = "center";
      x = width / 2;
      break;
    case "LEFT":
      ctx.textAlign = "left";
      x = offset;
      break;
    case "RIGHT":
      ctx.textAlign = "right";
      x = width - offset;
      break;
    default:
    case "FIT":
      function fit(min, max) {
        if (max - min < 1) return min;
        var test = min + (max - min) / 2;
        ctx.font = test + "px " + family;
        var measured = ctx.measureText(text).width;
        return measured > width - 2 * offset ? fit(min, test) : fit(test, max);
      }
      size = fit(0, height);
      x = offset;
      // no fit performed
      if (height - size < 1) {
        ctx.textAlign = "center";
        x = width / 2;
      }
      break;
  }
  var y = height / 2 + size / 4;
  ctx.fillText(text, x, y);
  return canvas.toDataURL();
}

function wb_update(ringName, configuration) {
  const config = {
    size: "0.5",
    shape: "round",
    quality: "best",
    metal: "silver",
    ...configuration
  };

  //const size = SIZE_TO_STRING["0.25"];
  //const shape = SHAPE_TO_STRING["cushion"];
  const url = `${process.env.REACT_APP_RINGS_URL}/${config.wbShape}_${config.wbStyle}.gltf${process.env.REACT_APP_RINGS_COMPRESS}`;

  appendScene(
    url,
    false,
    false,
    function() {
      for (var i_index in wb_loaded) {
        if (wb_loaded[i_index]) {
          unloadScene(i_index);
          wb_loaded[i_index] = false;
        }
      }

      const metal = config.wbMetal
        ? METAL_MAP[config.wbMetal]
        : METAL_MAP[config.metal];

      wb_material_update(metal);

      if (config.wbColor === "diamond.black") {
        setMaterial(config.wbColor, ["GROUP", "WB Pave"]);
      } else {
        setMaterial("Diamond", ["GROUP", "WB Pave"]);
        setColor(config.quality, ["GROUP", "WB Pave"], "Diamond");
      }
      console.log(config);
      if (config.WbEngrave) {
        engraving_ao_wb = textureFromText(
          config.WbEngrave,
          1024,
          256,
          "arial",
          100,
          "CENTER",
          5
        );

        generateNormalMap(
          engraving_ao_wb,
          0.002,
          0.002,
          -1,
          "Sobel",
          false,
          false,
          false
        ).then(s => {
          engraving_normal_wb = s;
          update_engraving_wb();
        });
      }

      wb_loaded[url] = true;
    },
    function() {}
  );
}

function ring_update(ringName, config, url) {
  const metal = METAL_MAP[config.metal];
  const diamondQuality = config.quality;

  //setMaterial(metal, ["GROUP", "Metals"]);

  metall_material_update(metal);

  setDiamond(["GROUP", "Center Stone"], config);

  setColor(diamondQuality, ["GROUP", "Center Stone"], "diamond.latest");

  const side = ["GROUP", "Pave Sidestones"];

  if (config.side === "diamond.black") {
    setMaterial(config.side, side);
  } else {
    setMaterial("Diamond", side);
    setColor(diamondQuality, side, "Diamond");
  }

  const halo = ["GROUP", "Halo Sidestones"];

  if (config.halo === "diamond.black") {
    setMaterial(config.halo, halo);
  } else {
    setMaterial("Diamond", halo);
    setColor(diamondQuality, halo, "Diamond");
  }

  if (config.engrave) {
    engraving_ao = textureFromText(
      config.engrave,
      1024,
      256,
      "arial",
      100,
      "CENTER",
      5
    );

    generateNormalMap(
      engraving_ao,
      0.002,
      0.002,
      -1,
      "Sobel",
      false,
      false,
      false
    ).then(s => {
      engraving_normal = s;
      update_engraving();
    });
  }

  if (config.wbShape) {
    wb_update(ringName, config, url);
  } else {
    for (var i_index in wb_loaded) {
      if (wb_loaded[i_index]) {
        unloadScene(i_index);
        wb_loaded[i_index] = false;
      }
    }
  }
}

export function switchRing(ringName = "CONSTELLATION", configuration = {}, cb) {
  const config = {
    size: "0.5",
    shape: "round",
    quality: "best",
    metal: "silver",
    ...configuration
  };
  const SIZE_TO_STRING = {
    "0.25": "C025",
    "0.5": "C05",
    "0.75": "C075",
    "1": "C01"
  };

  const SHAPE_TO_STRING = {
    round: "RB",
    princess: "PC",
    emerald: "EM",
    cushion: "CC"
  };

  const size = SIZE_TO_STRING[config.size];
  const shape = SHAPE_TO_STRING[config.shape];
  const url = `${process.env.REACT_APP_RINGS_URL}${[
    `/${ringName}`,
    shape,
    `${size}.gltf`
  ].join("_")}${process.env.REACT_APP_RINGS_COMPRESS}`;

  appendScene(
    url,
    false,
    false,
    function() {
      for (var i_index in ring_loaded) {
        if (ring_loaded[i_index]) {
          unloadScene(i_index);
          ring_loaded[i_index] = false;
        }
      }

      ring_update(ringName, config, url);

      ring_loaded[url] = true;

      if (cb) {
        cb();
      }
    },
    function() {}
  );
}

const materials = [
  { name: "better", type: "color", r: 0.776, g: 0.792, b: 0.757 },
  { name: "good", type: "color", r: 0.886, g: 0.851, b: 0.774 },
  { name: "best", type: "color", r: 1, g: 1, b: 1 },
  { name: "better.rd", type: "color", r: 0.776, g: 0.792, b: 0.757 },
  { name: "good.rd", type: "color", r: 0.886, g: 0.851, b: 0.774 },
  { name: "best.rd", type: "color", r: 1, g: 1, b: 1 },
  { name: "black", type: "color", r: 0, g: 0, b: 0 }
];

function replaceTexture(matName, texName, url, doCb) {
  var textures = matGetEditableTextures(matName, true).filter(function(elem) {
    return elem.name === texName;
  });

  if (!textures.length) return;

  if (url instanceof Promise) {
    url.then(
      function(response) {
        processURL(response);
      },
      function(error) {}
    );
  } else if (typeof url === "string") {
    processURL(url);
  } else {
    return;
  }

  function processURL(url) {
    var isHDR = url.search(/\.hdr$/) > 0;
    let loader;
    if (!isHDR) {
      loader = new window.v3d.ImageLoader();
      loader.setCrossOrigin("Anonymous");
    } else {
      loader = new window.v3d.FileLoader();
      loader.setResponseType("arraybuffer");
    }

    loader.load(url, function(image) {
      // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
      var isJPEG =
        url.search(/\.(jpg|jpeg)$/) > 0 || url.search(/^data:imag\/jpeg/) === 0;

      textures.forEach(function(elem) {
        if (!isHDR) {
          elem.image = image;
        } else {
          // parse loaded HDR buffer
          var rgbeLoader = new window.v3d.RGBELoader();
          var texData = rgbeLoader.parse(image);

          // NOTE: reset params since the texture may be converted to float
          elem.type = window.v3d.UnsignedByteType;
          elem.encoding = window.v3d.RGBEEncoding;

          elem.image = {
            data: texData.data,
            width: texData.width,
            height: texData.height
          };

          elem.magFilter = window.v3d.LinearFilter;
          elem.minFilter = window.v3d.LinearFilter;
          elem.generateMipmaps = false;
          elem.isDataTexture = true;
        }

        elem.format = isJPEG ? window.v3d.RGBFormat : window.v3d.RGBAFormat;
        elem.needsUpdate = true;

        // update world material if it is using this texture
        var wMat = app.worldMaterial;
        if (wMat)
          for (var texName in wMat.nodeTextures)
            if (wMat.nodeTextures[texName] === elem)
              app.updateEnvironment(wMat);

        doCb();
      });
    });
  }
}

// Describe this function...
function update_engraving() {
  replaceTexture("metall", "test.png", engraving_ao, function() {});
  replaceTexture("metall", "normal.png", engraving_normal, function() {});
}

function update_engraving_wb() {
  replaceTexture("wbmetall", "test.png", engraving_ao_wb, function() {});
  replaceTexture("wbmetall", "normal.png", engraving_normal_wb, function() {});
}

function matGetColors(matName) {
  var mat = window.v3d.SceneUtils.getMaterialByName(app, matName);
  if (!mat) return [];

  if (mat.isMeshNodeMaterial) return Object.keys(mat.nodeRGBMap);
  else if (mat.isMeshStandardMaterial) return ["color", "emissive"];
  else return [];
}

function setMaterialColor(matName, colName, r, g, b, cssCode) {
  var colors = matGetColors(matName);

  if (colors.indexOf(colName) < 0) return;

  if (cssCode) {
    var color = new window.v3d.Color(cssCode);
    color.convertSRGBToLinear();
    r = color.r;
    g = color.g;
    b = color.b;
  }

  var mats = window.v3d.SceneUtils.getMaterialsByName(app, matName);

  for (var i = 0; i < mats.length; i++) {
    var mat = mats[i];

    if (mat.isMeshNodeMaterial) {
      var rgbIdx = mat.nodeRGBMap[colName];
      mat.nodeRGB[rgbIdx].x = r;
      mat.nodeRGB[rgbIdx].y = g;
      mat.nodeRGB[rgbIdx].z = b;
    } else {
      mat[colName].r = r;
      mat[colName].g = g;
      mat[colName].b = b;
    }
    mat.needsUpdate = true;

    if (mat === app.worldMaterial) app.updateEnvironment(mat);
  }
}

// Describe this function...
function metall_material_update(color) {
  //setMaterialValue("metall", "metall_roughness", metall_colors[0]);
  setMaterialColor(
    "metall",
    "metall_color_1",
    metall_colors[color][1],
    metall_colors[color][2],
    metall_colors[color][3],
    ""
  );
  setMaterialColor(
    "metall",
    "metall_color_2",
    metall_colors[color][4],
    metall_colors[color][5],
    metall_colors[color][6],
    ""
  );
}
function wb_material_update(color) {
  //setMaterialValue("metall", "metall_roughness", metall_colors[0]);
  setMaterialColor(
    "wbmetall",
    "metall_color_1",
    metall_colors[color][1],
    metall_colors[color][2],
    metall_colors[color][3],
    ""
  );
  setMaterialColor(
    "wbmetall",
    "metall_color_2",
    metall_colors[color][4],
    metall_colors[color][5],
    metall_colors[color][6],
    ""
  );
}

export function openFile(ref, engrave, callback) {
  var ctx = ref.current.getContext("2d");
  ref.current.width = 3000;
  ref.current.height = 164;
  ctx.font = "80px Georgia";
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.fillRect(0, 0, 3000, 164);

  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.fillStyle = "rgb(0,0,0)";
  ctx.fillText(engrave, 2000, 164 / 2);
  callback(ref.current.toDataURL("image/png"));
}

function matGetEditableTextures(matName, collectSameNameMats) {
  var mats = [];
  if (collectSameNameMats) {
    mats = window.v3d.SceneUtils.getMaterialsByName(app, matName);
  } else {
    var firstMat = window.v3d.SceneUtils.getMaterialByName(app, matName);
    if (firstMat !== null) {
      mats = [firstMat];
    }
  }

  var textures = mats.reduce(function(texArray, mat) {
    var matTextures = [];
    switch (mat.type) {
      case "MeshNodeMaterial":
        matTextures = Object.values(mat.nodeTextures);
        break;

      case "MeshBlenderMaterial":
        matTextures = [
          mat.map,
          mat.lightMap,
          mat.aoMap,
          mat.emissiveMap,
          mat.bumpMap,
          mat.normalMap,
          mat.displacementMap,
          mat.specularMap,
          mat.alphaMap,
          mat.envMap
        ];
        break;

      case "MeshStandardMaterial":
        matTextures = [
          mat.map,
          mat.lightMap,
          mat.aoMap,
          mat.emissiveMap,
          mat.bumpMap,
          mat.normalMap,
          mat.displacementMap,
          mat.roughnessMap,
          mat.metalnessMap,
          mat.alphaMap,
          mat.envMap
        ];
        break;
      default:
      case "MeshPhongMaterial":
        matTextures = [
          mat.map,
          mat.lightMap,
          mat.aoMap,
          mat.emissiveMap,
          mat.bumpMap,
          mat.normalMap,
          mat.displacementMap,
          mat.specularMap,
          mat.alphaMap,
          mat.envMap
        ];
        break;
    }

    Array.prototype.push.apply(texArray, matTextures);
    return texArray;
  }, []);

  return textures.filter(function(elem) {
    // check Texture type exactly
    return (
      elem &&
      (elem.constructor === window.v3d.Texture ||
        elem.constructor === window.v3d.DataTexture)
    );
  });
}

export function oldreplaceTexture(matName, texName, url, doCb) {
  var textures = matGetEditableTextures(matName).filter(function(elem) {
    return elem.name === texName;
  });
  if (!textures.length) return;

  var isHDR = url.search(/\.hdr$/) > 0;
  let loader;
  if (!isHDR) {
    loader = new window.v3d.ImageLoader();
    loader.setCrossOrigin("Anonymous");
  } else {
    loader = new window.v3d.FileLoader();
    loader.setResponseType("arraybuffer");
  }

  loader.load(url, function(image) {
    // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
    var isJPEG =
      url.search(/\.(jpg|jpeg)$/) > 0 || url.search(/^data:imag\/jpeg/) === 0;

    textures.forEach(function(elem) {
      if (!isHDR) {
        elem.image = image;
      } else {
        // parse loaded HDR buffer
        var rgbeLoader = new window.v3d.RGBELoader();
        var texData = rgbeLoader._parser(image);

        // NOTE: reset params since the texture may be converted to float
        elem.type = window.v3d.UnsignedByteType;
        elem.encoding = window.v3d.RGBEEncoding;

        elem.image = {
          data: texData.data,
          width: texData.width,
          height: texData.height
        };

        elem.magFilter = window.v3d.LinearFilter;
        elem.minFilter = window.v3d.LinearFilter;
        elem.generateMipmaps = false;
        elem.isDataTexture = true;
      }

      elem.format = isJPEG ? window.v3d.RGBFormat : window.v3d.RGBAFormat;
      elem.needsUpdate = true;

      // update world material if it is using this texture
      var wMat = app.worldMaterial;
      if (wMat)
        for (var texName in wMat.nodeTextures)
          if (wMat.nodeTextures[texName] === elem) app.updateEnvironment(wMat);

      doCb();
    });
  });
}

export function screenshot() {
  cameraReset();
  return getCanvas();
}

export const getCanvas = () => {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      resolve(app.renderer.domElement.toDataURL());
    }, 1000);
  });
};

function cameraReset() {
  app.controls.reset();
  app.camera.position.set(
    -2.277202129364014,
    5.197676181793213,
    4.096951484680176
  );
  app.camera.updateMatrix();
  app.controls.update();
}
