import { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import { fabric } from "fabric";

class ImageBuilder extends Component {
  static contextTypes = {
    getProduct: PropTypes.func.isRequired,
    getUserImage: PropTypes.func.isRequired
  };

  render() {
    const base = process.env.REACT_APP_IMAGE || "/image?";

    const query = {
      product: this.props.productId,
      view: this.props.view
    };

    const product = this.props.configuration || this.context.getProduct();

    product.forEach(p => {
      if (p.value) {
        query[p.sku] = p.value;
      }

      if (p.id) {
        query[p.sku] = p.id;
      }

      if (p.font) {
        query[`${p.sku}-font`] = p.font;
      }

      if (p.language) {
        query[`${p.sku}-lang`] = p.language;
      }
    });

    if (!query.name) {
      query.name = "Mbali";
    }

    query.noCache = new Date().getTime();

    const imageUrl = `${base}${queryString.stringify(query)}`;

    if (this.props.render) {
      return this.props.render(imageUrl);
    }

    let imageLayers = [];

    if (this.props.masks && this.props.masks.length) {
      const { masks } = this.props;

      masks
        .filter(m => {
          if (m.type === "mask-remote") return true;

          if (query["eng-metal"]) {
            return m.metals.includes(query["eng-metal"]);
          }
          return m.default;
        })
        .forEach(m => {
          let src = `${process.env.REACT_APP_BUILD}${m.asset}`;
          if (m.type === "mask-remote") {
            src = `${base}${queryString.stringify({
              ...query,
              mask: 1
            })}`;
          }

          imageLayers.push({
            src,
            type: "image",
            options: {
              originX: "left",
              originY: "top",
              left: 0,
              top: 0,
              selectable: false,
              hasControls: false
            }
          });
        });
    }

    if (this.props.userImg) {
      imageLayers.push(this.props.userImg);
    } else if (!this.props.userImg) {
      const img = this.context.getUserImage();
      if (img.length) {
        imageLayers.push({
          type: "userImage",
          src: img[0],
          z: 0,
          options: {
            left: img[1] || 0,
            top: img[2] || 0,
            angle: img[3] || 0,
            scaleX: img[4] || 1,
            scaleY: img[5] || 1,
            width: img[6] || 1,
            height: img[7] || 1,
            originX: "left",
            originY: "top"
          }
        });
      }
    }

    imageLayers.push({
      type: "overlay",
      src: imageUrl,
      z: 0,
      options: {
        originX: "left",
        originY: "top",
        left: 0,
        top: 0,
        clipName: "object",
        selectable: false,
        hasControls: false
      }
    });

    console.log(imageLayers, this.props.userImg);

    return (
      <FabricCanvas configuration={imageLayers} userImg={this.props.userImg} />
    );
  }
}

ImageBuilder.defaultProps = {
  view: "view1",
  masks: []
};
export default ImageBuilder;

class FabricCanvas extends React.Component {
  componentDidMount() {
    // Make a New Canvas
    window.the_canvas = new fabric.Canvas("main-canvas", {
      width: 1000,
      height: 1500,
      preserveObjectStacking: true,
      controlsAboveOverlay: true
    });

    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "rgba(0,0,0,1)",
      cornerSize: 24,
      padding: 0 // do not change
    });

    this.userImg = null;

    this.updateCanvasforImage();
  }

  componentDidUpdate = newprops => {
    if (newprops.configruation !== this.props.configuration) {
      this.updateCanvasforImage();
    }

    if (this.props.userImg === null && newprops.userImg !== null) {
      window.the_canvas.clear();
      this.updateCanvasforImage();
    }
  };

  addImage = obj => {
    fabric.Image.fromURL(obj.src, img => {
      var img1 = img
        .scale(1)
        .set({ left: 0, top: 0, selectable: false, hasControls: false });
      img1.scaleToWidth(window.the_canvas.getWidth());
      window.the_canvas.insertAt(img1, 0, true);
      window.the_canvas.centerObject(img1);
    });
  };

  getUserImage = obj => {
    console.log(obj);
    if (this.userImg && obj.src === this.userImg) {
      return;
    }

    if (!obj.src) return;

    fabric.Image.fromURL(obj.src, img => {
      this.userImg = obj.src; //Make this a time stamp
      var img2 = img.set({
        opacity: 0.75,
        selectable: true,
        hasControls: true,
        ...obj.options
      });

      if (obj.scaleHeight) {
        img2.scaleToHeight(obj.scaleHeight);
      }

      img2.applyFilters();
      window.the_canvas.insertAt(img2, 1, true);

      if (obj.centerObject) {
        window.the_canvas.centerObject(img2);
      }

      img2.setCoords();
      window.the_canvas.setActiveObject(img2);
    });
  };

  getOverlayImage = obj => {
    window.the_canvas.setOverlayImage(
      `${obj.src}`,
      window.the_canvas.renderAll.bind(window.the_canvas),
      {
        left: 0,
        top: 0,
        selectable: false,
        hasControls: false
      }
    );
  };

  getCanvassElement(obj) {
    switch (obj.type) {
      case "userImage":
        return this.getUserImage(obj);
      case "image":
        return this.addImage(obj);
      case "overlay":
        return this.getOverlayImage(obj);
      default:
        return null;
    }
  }

  updateCanvasforImage = (onlyBase = false) => {
    const { configuration } = this.props;

    configuration.forEach(obj => {
      this.getCanvassElement(obj);
    });
  };

  saveToCanvas = () => {
    let link = document.createElement("a");
    link.href = window.the_canvas.toDataURL({ format: "png" });
    link.download = "avatar.png";
    link.click();
  };

  render() {
    return (
      <div className="main-canvas-container">
        <div className="main-canvas-container--mobileOverlay">
          <div className="main-canvas-container--mobileOverlaySides">
            <canvas id="main-canvas" />
          </div>
        </div>
      </div>
    );
  }
}
