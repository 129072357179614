import React from 'react';
import { Copy } from '@lucio-erasmus/sterns-components';

const RadioField = ({
  id,
  className,
  error,
  options,
  description,
  ...input
}) => {
  return (
    <div className={className}>
      {options.map(option => (
        <div key={option.value} className="form-check">
          <input
            className="form-check-input"
            name={id}
            type="radio"
            value={option.value}
            id={option.value}
            {...input}
          />
          <label className="form-check-label" for={option.value}>
            {option.label}
          </label>
        </div>
      ))}
      {error && (
        <Copy mb="0" color="error" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

RadioField.defaultProps = {
  className: 'col-md-12',
};

export default RadioField;
