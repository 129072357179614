import React from 'react';
import { Copy } from '@lucio-erasmus/sterns-components';

const TextField = ({
  id,
  className,
  error,
  label,
  type,
  placeholder,
  description,
}) => {
  return (
    <div className={`${className} form-group`}>
      <label>{label}</label>
      <textarea
        name={id}
        placeholder={placeholder}
        type={type}
        className="form-control"
      />
      {error && (
        <Copy mb="0" color="red" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

TextField.defaultProps = {
  className: 'col-md-6 form-group',
};

export default TextField;
