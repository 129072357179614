import React, { useState, useLayoutEffect } from "react";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import {
  Border,
  Loading,
  Copy,
  Button,
  StoreFrontHeader
} from "@lucio-erasmus/sterns-components";
import { NewsletterContainer } from "..";
import { getBespokePage } from "../../helpers/wp";
import FormContainer from "./../Form/FormContainer";
import { siteConfig } from "../../helpers/config";
import { uploadToS3 } from "../../helpers/s3";
import Seo from "../../components/Seo/Seo";
const FORM_ID = "bespoke";
const BespokeContainer = props => {
  const [p, setPage] = useState({ loading: true, page: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  useLayoutEffect(() => {
    setPage({
      loading: true,
      page: null
    });
    getBespokePage(props.match.params.slug).then(data => {
      setPage({
        loading: false,
        page: data
      });
    });
  }, [props.match.params.slug]);

  const onSubmit = async (errors, values, file) => {
    setLoading(true);

    const promises = [];

    if (file) {
      promises.push(uploadToS3(file));
    } else {
      promises.push(Promise.resolve(null));
    }

    await Promise.all(promises)
      .then(([file]) => {
        const { bespoke } = values;
        bespoke.form = FORM_ID;

        return props.createBespoke({
          variables: {
            input: {
              ...values.bespoke,
              url: file
            }
          }
        });
      })
      .then(({ data }) => {
        const { createBespokeRequest } = data;
        if (createBespokeRequest) {
          setSent(true);
        }

        setLoading(false);
      })
      .catch(err => {
        console.log(errors);
        setError(err);
      });
  };

  if (p.loading) {
    return <Loading />;
  }

  const { metaDesc, fullHead } = p.page.seo;

  return (
    <>
      <Seo metaDesc={metaDesc} fullHead={fullHead} />
      {
        <StoreFrontHeader
          headline={
            p.page && !p.page.headlineOptions.hideText
              ? p.page && p.page.headlineOptions.headline
                ? p.page.headlineOptions.headline
                : p.page.title
              : null
          }
          image={
            p.page &&
            p.page.heroImageOptions.heroImage &&
            p.page.heroImageOptions.heroImage.url
          }
          description={
            p.page && !p.page.headlineOptions.hideText
              ? p.page && p.page.headlineOptions.description
              : null
          }
          color={
            p.page && p.page.headlineOptions
              ? p.page.headlineOptions.color
              : "white"
          }
        />
      }
      {/* {p.DYOR.headlineImage ? (
        <div className="text-center" style={{ margin: "auto" }}>
          <img
            style={{ height: "80px", width: "auto" }}
            alt={p.headline ? p.headline : p.title}
            className="img-fluid pl-3 pr-3"
            src={p.DYOR.headlineImage.mediaItemUrl}
          />
        </div>
      ) : (
        <div className="p-3">
          <Heading center size={"large"} mb={"small"} html={p.title} />
        </div>
      )} */}
      <div className="container entry">
        <div className="row">
          {p.page.content && (
            <div className="col-md-6">
              <Copy html={p.page.content} mb={"large"} />
            </div>
          )}
          <div className="col-md-5 ml-auto">
            {sent ? (
              <Copy color="success" size="small">
                {siteConfig.bespokeConfirmation}
              </Copy>
            ) : (
              <div className="border p-3">
                <Copy size="tiny">
                  Complete the details below and we'll get back to you within 5
                  working days.
                </Copy>
                <FormContainer
                  formId="bespoke"
                  submitProcess={onSubmit}
                  renderSubmit={() => (
                    <div className="col-md-12">
                      <Button full iconAfter="arrow-right" size="large">
                        {loading ? "Loading" : "Submit"}
                      </Button>
                    </div>
                  )}
                />
                {error && (
                  <div className="pt-3">
                    <Copy size="small" color="error">
                      {siteConfig.bespokeError}
                    </Copy>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Border color="white" />
      </div>
      <NewsletterContainer />
    </>
  );
};

const PAGE_QUERY = gql`
  query getProduct($slug: String!) {
    page(slug: $slug) {
      id
      title
      content
      excerpt
      description
      subtitle
      headline
      image: featured_media {
        source_url
      }
      heroImage: image {
        url
        hotspot
      }
      featured_media {
        source_url
      }
      pageOptions {
        isFullWidth
      }
    }
  }
`;

const CREATE_BESPOKE_MUTATION = gql`
  mutation createBespoke($input: BespokeInput) {
    createBespokeRequest(input: $input)
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: "design-your-own-ring/bespoke-design-studio"
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

const withCreateBespoke = graphql(CREATE_BESPOKE_MUTATION, {
  name: "createBespoke"
});

export default compose(withPage, withCreateBespoke)(BespokeContainer);
