import React, { useEffect, useState } from "react";
import { initVerge3D, switchRing } from "../v3d/config";
import { Loading, Heading, Image } from "@lucio-erasmus/sterns-components";
import icon from "../../../images/3d.png";

const RingModel = ({ configuration, ringName }) => {
  const [preloader, setPreloader] = useState({
    loading: true,
    percentage: 0
  });

  const config = configuration.reduce((result, item) => {
    if (item.specification) {
      Object.keys(item.specification).forEach(spec => {
        result[spec] = item.specification[spec];
      });
    }
    return result;
  }, {});

  useEffect(() => {
    initVerge3D(
      `${process.env.REACT_APP_RINGS_URL}/Rings_RADIANTSTAR.gltf${process.env.REACT_APP_RINGS_COMPRESS}`,
      {
        useFullscreen: true,
        preserveDrawBuf: true,
        useCustomPreloader: true,
        preloaderProgressCb: value => {},
        preloaderEndCb: () => {}
      },
      () => {
        switchRing(ringName, {}, () => {
          setPreloader({
            ...preloader,
            loading: false
          });
        });
      }
    );
  }, []);

  useEffect(() => {
    switchRing(ringName, config);
  }, [config]);

  return (
    <div>
      <div className="main-canvas-container--mobileOverlay">
        <div className="main-canvas-container--mobileOverlaySides">
          <div id="verge3d-container">
            <div
              style={{
                width: "90px",
                position: "absolute",
                bottom: "0",
                left: "0"
              }}
            >
              <Image imageUrl={icon} />
            </div>
            {preloader.loading ? (
              <div className="loader-div">
                <div className="loader-inner">
                  <div className="mb-2">
                    <Loading minHeight={false} />
                  </div>
                  <Heading>Loading 3D model...</Heading>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RingModel;
