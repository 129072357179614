import React from 'react';
import { Copy, Heading } from "@lucio-erasmus/sterns-components";

const PageNotFound = () => {
  return (
    <>
      <Heading sans size="medium" mb={"small"} center>
        404 - Page Not Found
      </Heading>
      <Copy center mb={"large"}>
        The page you are looking for does not exist.
      </Copy>
    </>
  );
}

export default PageNotFound