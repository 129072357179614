import React, { Component } from "react";
import { graphql } from "react-apollo";
import { gql } from "apollo-boost";
import { Loading } from "@lucio-erasmus/sterns-components";
import Helmet from "react-helmet";
import ProductContainer from "../Product/ProductContainer";
import RingContainer from "../Ring/RingContainer";

class ShopContainer extends Component {
  render() {
    if (this.props.loading) return <Loading />;
    const { product: p } = this.props;

    return (
      <React.Fragment>
        <Helmet
          defaultTitle={`${p.name} - Sterns`}
          meta={[
            { name: "description", content: p.short_description },
            { name: "og:title", content: `${p.name} - Sterns` },
            { name: "og:description", content: p.short_description },
            {
              name: "og:url",
              content: `https://personalise.sterns.co.za/product/${p.slug}`
            },
            { name: "og:site_name", content: `Sterns` },
            { name: "og:type", content: `website` },
            {
              name: "og:image",
              content: p.featuredImage.hasImage ? p.featuredImage.url : ""
            }
          ]}
          htmlAttributes={{ lang: "en" }}
        />
        {p.productType === "dyor" ? (
          <RingContainer {...this.props} />
        ) : (
          <ProductContainer {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

ShopContainer.fragments = {
  components: gql`
    fragment NameParts on ConfigComponent {
      type
      name
      description
      note
      empty
      sku
      parentSku
      query
      price
      groups
      group
      required
      hasPadding
      multiple
      limit
      allUpcase
      text
      fonts {
        id
        title
        image
      }
      specialChars {
        type
        name
        char
      }
      languages {
        id
        language
        code
      }
      regEx
      className
      items {
        name
        group
        image
        description
        assetImage
        id
        sku
        parentSku
        price
        priceCategory
        exclusions {
          key
          values
        }
      }
    }
  `
};

const PRODUCT_QUERY = gql`
  ${ShopContainer.fragments.components}
  query getProduct($productId: ID!) {
    product(id: $productId) {
      id
      name
      short_description
      regular_price
      salesPriceText
      productType
      from_price
      on_sale
      priceText
      variations
      component_description {
        description
        sku
      }
      featuredImage {
        url
        hotspot
        hasImage
      }
      specifications {
        value
      }
      shipping_info {
        value
      }
      categories {
        id
      }
      eyeCatcher {
        value
      }
      related {
        id
        slug
        name
        short_description
        priceText
        salesPriceText
        on_sale
        from_price
        variations
        featuredImage {
          url
          hotspot
          hasImage
        }
        eyeCatcher {
          value
        }
      }
      modals {
        component_id
        title
        content
      }
      configuration {
        id
        name
        style
        requirements
        views
        statics {
          type
          asset
          default
          metals
        }
        pricing {
          id
          price
          additions {
            category
            price
            additions {
              category
              price
            }
          }
        }
        components {
          exclusions {
            key
            values
          }
          additionalLimits {
            size
            limit
            shapes
          }
          ...NameParts
          options {
            ...NameParts
            styles {
              ...NameParts
            }
            items {
              name
              group
              image
              description
              assetImage
              id
              sku
              parentSku
              price
              priceCategory
              exclusions {
                key
                values
              }
              styles {
                ...NameParts
              }
            }
            exclusions {
              key
              values
            }
          }
          styles {
            ...NameParts
          }
          items {
            name
            group
            image
            description
            assetImage
            id
            sku
            parentSku
            price
            priceCategory
            exclusions {
              key
              values
            }
            styles {
              ...NameParts
            }
          }
        }
      }
    }
    ranges {
      id
      title
      slug
      content
      excerpt
      headline
      connected_category
    }
  }
`;

const withProduct = graphql(PRODUCT_QUERY, {
  options: props => {
    return {
      variables: {
        productId: props.match.params.productId
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withProduct(ShopContainer);
